import React, { useEffect, useState } from 'react';
import { fetchUserTeamActivities } from '../../../api';
import { TeamActivitiesType } from '../../../types/teamActivityTypes';

const UserActivities: React.FC = () => {
  const [activities, setActivities] = useState<TeamActivitiesType[]>([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  useEffect(() => {
    const fetchActivities = async () => {
      try {
        const response = await fetchUserTeamActivities(page);
        setActivities(response.data);
        setTotalPages(response.pagination.totalPages); // Set totalPages from the response
      } catch (error) {
        console.error('Failed to fetch user activities:', error);
      }
    };

    fetchActivities();
  }, [page]);

  const formatActivityMessage = (activity: TeamActivitiesType) => {
    switch (activity.activityType) {
      case 'Error Resolved':
        return (
          <>
            「{activity.activityDetails.postTitle}」を
            <span className="bold">解決</span>
            しました。
          </>
        );
      case 'Feedback Created':
        return (
          <>
            「{activity.activityDetails.postTitle}」に
            <span className="bold">コメント</span>
            を追加しました。
          </>
        );
      case 'Post Created':
        return (
          <>
            新しい投稿
            <span className="bold">「{activity.activityDetails.postTitle}」</span>
            を登録しました。
          </>
        );
      default:
        return '';
    }
  };

  return (
    <>
    {activities.length === 0 ? (
      <div className="text-center">
        <p>最近の活動がありません。</p>
      </div>
      ) : (
        <>
      <ul className="activity-list">
        {activities.map((activity) => (
          <li key={activity.activityId}>
            <strong>{new Date(activity.createdAt).toLocaleDateString()}</strong>:
            {formatActivityMessage(activity)}
          </li>
        ))}
      </ul>
      {/* Pagination Controls */}
      <div className="pagination-next">
        {page > 1 && (
          <button onClick={() => setPage(page - 1)}>&lt;</button>
        )}
        <span>{page}</span>
        {page < totalPages && (
          <button onClick={() => setPage(page + 1)}>&gt;</button>
        )}
      </div>
      </>
      )}
    </>
  );
};

export default UserActivities;
