const API_URL = process.env.REACT_APP_API_URL;

export const apiClient = {
  async request(endpoint: string, options: RequestInit & { headers?: Record<string, string> } = {}): Promise<Response> {
    const url = `${API_URL}${endpoint}`;

    // ローカルストレージからアクセストークンを取得
    let accessToken = localStorage.getItem('fixhub_auth_ktn');

    const mergedOptions: RequestInit = {
      ...options,
      headers: {
        ...options.headers,
        ...(accessToken && { Authorization: `Bearer ${accessToken}` }),
      },
      credentials: 'include', // クッキーを送信するため
    };

    const response = await fetch(url, mergedOptions);
    
    // トークンが期限切れの場合、トークンを再発行
    if (response.status === 401 && !options.headers?.['Authorization']) {
      console.log('トークンが期限切れです。再発行します。');
      await this.refreshToken();

      accessToken = localStorage.getItem('fixhub_auth_ktn');
      mergedOptions.headers = {
        ...options.headers,
        ...(accessToken && { Authorization: `Bearer ${accessToken}` })
      };

      const retryResponse = await fetch(url, mergedOptions);
      if (!retryResponse.ok) {
        const errorResponse = await retryResponse.json();
        console.error('API エラー:', errorResponse);
        throw new Error('Network response was not ok');
      }
      return retryResponse;
    }

    if (!response.ok) {
      const errorResponse = await response.json();
      console.error('API エラー:', errorResponse);
      const error = new Error('Network response was not ok');
      (error as any).response = errorResponse;
      throw error;
    }
    return response;
  },

  async refreshToken(): Promise<void> {
    try {
      const refreshTokenCookie = document.cookie.split('; ').find(row => row.startsWith('fixhub_rfr_ktn='));
      const refreshToken = refreshTokenCookie ? refreshTokenCookie.substring('fixhub_rfr_ktn='.length) : null;
      if (!refreshToken) {
        throw new Error('Refresh token not found');
      }
      const response = await this.post('/users/refresh-token', { refreshToken });
      console.log('トークン再発行:', response);
      const { accessToken } = await response.data;
      localStorage.setItem('fixhub_auth_ktn', accessToken);
    } catch (error) {
      console.error('トークン再発行エラー:', error);
    }
  },

  async get(endpoint: string): Promise<any> {
    const response = await this.request(endpoint);
    return response.json();
  },

  async post(endpoint: string, data: any): Promise<any> {
    const response = await this.request(endpoint, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: { 'Content-Type': 'application/json' } as Record<string, string>,
    });
    return response.json();
  },

  async postNoJson(endpoint: string, data: any): Promise<any> {
    return this.request(endpoint, {
      method: 'POST',
      body: data,
      headers: {}  // ヘッダーを空にする
    }).then(response => response.json());
  },

  async put(endpoint: string, data: any, additionalOptions: RequestInit = {}): Promise<any> {
    const response = await this.request(endpoint, {
      method: 'PUT',
      body: JSON.stringify(data),
      headers: { 'Content-Type': 'application/json' } as Record<string, string>,
    });
    return response.json();
  },

  async putNoJson(endpoint: string, data: any): Promise<any> {
    return this.request(endpoint, {
      method: 'PUT',
      body: data,
      headers: {}  // ヘッダーを空にする
    }).then(response => response.json());
  },

  async delete(endpoint: string): Promise<any> {
    const response = await this.request(endpoint, {
      method: 'DELETE',
    });
    return response.json();
  },

  async patch(endpoint: string, data?: any): Promise<any> {
    const response = await this.request(endpoint, {
      method: 'PATCH',
      body: JSON.stringify(data),
      headers: { 'Content-Type': 'application/json' } as Record<string, string>,
    });
    return response.json();
  },
};