import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { fetchTags, createPost, addFilesToPost, addFilesToDraftPost  } from '../../api';
import { fetchDraftPostForEdit, createDraftPost, updateDraftPost } from '../../api/draftPosts';
import { ERROR_STATUS, ErrorStatus } from '../../constants/ErrorStatus';
import { Tag } from '../../types/TagTypes';
import { PostRequest } from '../../types/PostTypes';
import PostTypeSelector from './components/PostTypeSelector';
import PostForm from './components/PostForm';
import ErrorFields from './components/ErrorFields';
import TagSelector from './components/TagSelector';
import { DraftPostsRequest } from '../../types/DraftPostsTypes';
import './CreatePosts.css';

const CreatePosts: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [draftId, setDraftId] = useState<number | undefined>(undefined);
  const [postType, setPostType] = useState<'normal' | 'error'>('normal');
  const [tempPostId, setTempPostId] = useState<number | undefined>(undefined);
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [tags, setTags] = useState<Tag[]>([]);
  const [selectedTags, setSelectedTags] = useState<number[]>([]);
  const [errorFields, setErrorFields] = useState({
    environment: '',
    message: '',
    status: ERROR_STATUS.UNSOLVED as ErrorStatus
  });
  const [fileIds, setFileIds] = useState<number[]>([]);
  const [isCreatingPost, setIsCreatingPost] = useState(false);
  const [isCreatingDraftPost, setIsCreatingDraftPost] = useState(false);

  useEffect(() => {
    fetchTags().then(result => { 
      setTags(result.data);
    }).catch(error => console.error('タグ取得エラー:', error));

    const searchParams = new URLSearchParams(location.search);
    const draftIdParam = searchParams.get('draftId');
    setTempPostId(Number(draftIdParam));

    if (draftIdParam) {
      fetchDraftPostForEdit(Number(draftIdParam))
        .then(result => {
          const data = result.data;

          // 一時保存投稿が投稿として登録された履歴がある場合
          if (data.postId) {
            navigate(`/posts/edit?type=draft&id=${data.tempPostId}`);

          // 一時保存投稿が投稿として登録された履歴がない場合
          } else {  
            setDraftId(data.tempPostId);
            setTitle(data.title);
            setContent(data.content || '');
            setSelectedTags(data.tagIds || []);
            setPostType(data.isErrorReport ? 'error' : 'normal');
            if (data.isErrorReport) {
              setErrorFields({
                environment: data.errorEnvironment || '',
                message: data.errorMessage || '',
                status: data.errorStatus || ERROR_STATUS.UNSOLVED,
              });
            }
          }
        })
        .catch(error => {
          console.error('一時保存投稿取得エラー:', error);
          if (error.response && error.response.data) {
            const { identifier } = error.response.data;
    
            if (identifier === 'AUTHOR_MISMATCH') {
              alert('アクセス権限がありません。');
            }
          } else {
            alert('一時保存投稿の取得に失敗しました。');
          }
          navigate(-1);
        });
    }
  }, [location, navigate]);

  // 投稿データ準備
  const preparePostData = (): PostRequest => ({
    tempPostId, // 一時保存履歴がある場合はそのIDを設定
    title,
    content,
    tagIDs: selectedTags,
    isErrorReport: postType === 'error' ? 1 : 0,
    ...(postType === 'error' && {
      environment: errorFields.environment,
      errorCode: errorFields.message,
      errorStatus: errorFields.status,
    }),
  });

  // 一時保存データ準備
  const prepareDraftPostData = (): DraftPostsRequest => ({
    ...preparePostData()
  });

  const validateTitle = (): boolean => {
    if (!title.trim()) {
      alert('タイトルを入力してください。');
      return false;
    }
    return true;
  };

  const handleCreatePost = async () => {
    if (!validateTitle()) return;

    setIsCreatingPost(true);
    const postData = preparePostData();
    try {
      // 投稿作成 API の呼び出し
      const result = await createPost(postData);
      const postId = result.data.postId;

      // ファイルを PostFiles に追加する API の呼び出し
      if (fileIds.length > 0) {
        await addFilesToPost(postId, fileIds);
      }

      alert('投稿が正常に作成されました。');
      navigate(`/posts/${postId}`);
    } catch (error) {
      console.error('投稿作成エラー:', error);
      alert('投稿作成中にエラーが発生しました。');
    } finally {
      setIsCreatingPost(false);
    }
  };

  const handleCreateTempPost = async () => {
    if (!validateTitle()) return;

    setIsCreatingDraftPost(true);
    const draftPostData = prepareDraftPostData();
    try {
      if (draftId) {
        // 一時保存投稿更新 API の呼び出し
        await updateDraftPost(draftId, draftPostData);

        // ファイルを TempPostFiles に追加する API の呼び出し
        if (fileIds.length > 0) {
          await addFilesToDraftPost(draftId, fileIds);
        }

        alert('投稿が一時保存されました。');
      } else {
        // 一時保存投稿作成 API の呼び出し
        const result = await createDraftPost(draftPostData);
        const tempPostId = result.data.tempPostId;

        // ファイルを TempPostFiles に追加する API の呼び出し
        if (fileIds.length > 0) {
          await addFilesToDraftPost(tempPostId, fileIds);
        }

        alert('投稿が一時保存されました。');
        navigate('/drafts');
      }
    } catch (error) {
      console.error('一時保存エラー:', error);
      alert('一時保存中にエラーが発生しました。');
    } finally {
      setIsCreatingDraftPost(false);
    }
  };

  return (
    <div className="container">
      <h2>投稿作成</h2>
      <PostTypeSelector postType={postType} setPostType={setPostType} />
      <div className="form-container">
        <PostForm
          title={title}
          setTitle={setTitle}
          content={content}
          setContent={setContent}
          setFileIds={setFileIds} // ファイルIDを設定
        />
        {postType === 'error' && (
          <ErrorFields
            errorFields={errorFields}
            setErrorFields={setErrorFields}
          />
        )}
        <TagSelector
          tags={tags}
          selectedTags={selectedTags}
          setSelectedTags={setSelectedTags}
        />
        <button type="button" className="btn btn-primary" onClick={handleCreatePost} disabled={isCreatingPost}>
          {isCreatingPost ? <span className="text-secondary">登録中…</span> : '登録'}
        </button>
        <button type="button" className="btn btn-secondary" onClick={handleCreateTempPost} disabled={isCreatingDraftPost}>
          {isCreatingDraftPost ? <span className="text-white">一時保存中…</span> : '一時保存'}
        </button>
      </div>
    </div>
  );
};

export default CreatePosts;