import React, { useState, useEffect, useRef } from 'react';
import { useAuth } from '../../contexts/AuthContext';
import { getUserInfo, getSasUrl } from '../../api';
import { Link, useNavigate } from 'react-router-dom';
import profile from '../../assets/images/default-profile.png';
import logoImage from '../../assets/images/fixhub-logo-white.png';
import './Header.css';

const Header: React.FC = () => {
    const { isAuthenticated, isAdmin, logoutAuth } = useAuth();
    const [userInfo, setUserInfo] = useState<any>(null);
    const [profileImage, setProfileImage] = useState<string>(profile);
    const [isMenuVisible, setIsMenuVisible] = useState(false);
    const navigate = useNavigate();
    const menuRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const fetchUserDetails = async () => {
            if (isAuthenticated) {
                try {
                    const response = await getUserInfo();
                    setUserInfo(response.data);

                    if (response.data.profileImage) {
                        const sasUrlResponse = await getSasUrl(response.data.profileImage.blobFileName);
                        setProfileImage(sasUrlResponse.url);
                    }
                } catch (error) {
                    console.error('Error fetching user info:', error);
                }
            }
        };

        fetchUserDetails();
    }, [isAuthenticated]);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
                setIsMenuVisible(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const toggleMenu = () => setIsMenuVisible(!isMenuVisible);

    const handleLogout = () => {
        logoutAuth();
        navigate('/login');
    };

    return (
        <header className="header">
            <nav className="nav">
                {isAuthenticated ? (
                    <Link to="/" className="logo">
                        <img src={logoImage} alt="Fixhub Logo" className="logo-image" />
                        Fixhub
                    </Link>
                ) : (
                    <Link to="/initial" className="logo">
                        <img src={logoImage} alt="Fixhub Logo" className="logo-image" />
                        Fixhub
                    </Link>
                )}
                <ul className="nav-links">
                    {isAuthenticated && (
                        <>
                            <li><Link to="/posts-list">情報共有リスト</Link></li>
                            <li><Link to="/mypage">マイページ</Link></li>
                            {isAdmin && <li><Link to="/admin-tags">タグ管理</Link></li>}
                        </>
                    )}
                </ul>
                <div className="nav-right">
                    {isAuthenticated ? (
                        <>
                            <button className="create_post_btn" onClick={() => window.location.href = `/posts/create`}>投稿作成</button>
                            <div className="user-image-header" onClick={toggleMenu} ref={menuRef}>
                                <img src={profileImage || '../../assets/images/default-profile.png'} alt="Profile" />
                                {isMenuVisible && (
                                    <div className="user-menu">
                                        <ul><li>{userInfo?.handleName}</li></ul>
                                        <Link to="/profile">プロフィール</Link>
                                        <Link to="/settings">設定</Link>
                                        <button className="logout-button" onClick={handleLogout}>ログアウト</button>
                                    </div>
                                )}
                            </div>
                        </>
                    ) : (
                        <ul>
                            <li><Link to="/login">Sign in</Link></li>
                            <li><Link to="/register">Sign up</Link></li>
                        </ul>
                    )}
                </div>
            </nav>
        </header>
    );
};

export default Header;
