import React from 'react';
import PostCount from './components/PostCount';
import UserActivities from './components/UserActivities';
import ErrorReportList from './components/ErrorReportList';
import UserPostsList from './components/UserPostsList';
import './MyPage.css';

const MyPage: React.FC = () => {
  return (
    <main>
      <div className="container">
        <PostCount />
      </div>

      <div className="container">
        <h1>最近の活動</h1>
        <UserActivities />
      </div>

      <div className="container">
        <h1>自分が報告したエラーリスト</h1>
        <ErrorReportList />
      </div>

      <div className="container">
        <h1>自分が作成した知識共有リスト</h1>
        <UserPostsList />
      </div>
    </main>
  );
};

export default MyPage;
