import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { sendVerificationCode, verifyCode, signUp, checkEmail } from '../../api'; // APIサービス
import { UserRegistRequest } from '../../types/UserTypes'; // ユーザー情報型

const Register: React.FC = () => {
  const [email, setEmail] = useState('');
  const [authCode, setAuthCode] = useState('');
  const [handleName, setHandleName] = useState('');
  const [password, setPassword] = useState('');
  const [isPasswordVisible, setIsPasswordVisible] = useState(false); // パスワード表示/非表示状態
  const [isCodeSent, setIsCodeSent] = useState(false);
  const [isCodeVerified, setIsCodeVerified] = useState(false);
  const [timer, setTimer] = useState(180);
  const [error, setError] = useState('');
  const [isSendingCode, setIsSendingCode] = useState(false); // 認証コード送信中状態
  const navigate = useNavigate();

  // メールアドレスの有効性検査
  const validateEmail = (email: string) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  // パスワードの有効性検査
  const validatePassword = (password: string) => password.length >= 8 && /^(?=.*[a-zA-Z])(?=.*\d).+$/.test(password);
  // ハンドル名の有効性検査
  const validateHandleName = (name: string) => {
    const regex = /^[\u3040-\u309F\u30A0-\u30FF\uFF66-\uFF9F\u4E00-\u9FFF\u3000-\u303F\uFF01-\uFF5Ea-zA-Z0-9\s]{2,}$/;
    return regex.test(name);
  };


  // パスワード表示/非表示トグル
  const togglePasswordVisibility = () => {
    setIsPasswordVisible((prevState) => !prevState);
  };

  // 認証コード送信
  const handleSendCode = async () => {
    setIsSendingCode(true); // 認証コード送信中状態をtrueに設定

    if (!validateEmail(email)) {
      setError('メールアドレスの形式が正しくありません。');
      setIsSendingCode(false);
      return;
    }

    // メールアドレスの重複確認
    checkEmail(email).then((res) => {
      if (res.data.isRegistered) {
        setError('このメールアドレスは既に使用されています。');
        setIsSendingCode(false); // 認証コード送信中状態をfalseに設定
        return;
      } else {
        setError('');
        sendVerificationCode(email).then((response) => {
          setIsCodeSent(true);
          startTimer(); // タイマー開始
          setIsSendingCode(false); // 認証コード送信中状態をfalseに設定
        }).catch((err) => {
          if (err.response && err.response.data) {
            setError(err.response.data.message || '認証コードの送信に失敗しました。');
          } else {
            setError('認証コードの送信に失敗しました。');
          }
          setIsSendingCode(false); // 認証コード送信中状態をfalseに設定
        });
      }
    }).catch((err) => {
      setError('メールアドレスの重複確認に失敗しました。');
      setIsSendingCode(false); // 認証コード送信中状態をfalseに設定
    });
  };

  // 認証コード確認
  const handleVerifyCode = async () => {
    try {
      await verifyCode(email, authCode);
      setError('');

      setIsCodeVerified(true);
    } catch (error: any) {
      if (error.response && error.response.data) {
        const { identifier } = error.response.data;
        if (identifier === 'INVALID_CODE') {
          setError('認証コードの有効期限が切れています。');
        } else if (identifier === 'CODE_MISMATCH') {
          setError('認証コードが一致しません。');
        } else {
          setError('認証コードの確認に失敗しました。');
        }
      } else {
        setError('認証コードの確認に失敗しました。');
      }
    }
  };


  // サインアップ処理
  const handleSignUp = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!handleName || !password) {
      setError('入力内容を確認してください。');
      return;
    }
    if (!validateHandleName(handleName)) {
      setError('ハンドル名は2文字以上で入力してください。');
      return;
    }
    if (!validatePassword(password)) {
      setError('パスワードは8文字以上で、英数字を含めてください。');
      return;
    }

    if (!validateEmail(email)) {
      setError('メールアドレスの形式が正しくありません。');
      return;
    }

    try {
      const userinfo: UserRegistRequest = { email, handleName, password };
      await signUp(userinfo); // POST /users API呼び出し
      console.log('サインアップ成功');
      navigate('/login');

    } catch (error: any) {
      setError('');
      if (error.response && error.response.data) {
        const { identifier } = error.response.data;

        if (identifier === 'EXISTING_USER') {
          setError('このメールアドレスは既に使用されています。');
        } else {
          setError('サインアップに失敗しました。');
        }
      } else {
        setError('サインアップに失敗しました。');
      }
    }
  };

  // タイマー開始
  const startTimer = () => {
    setTimer(180); // 3分
    const countdown = setInterval(() => {
      setTimer((prev) => {
        if (prev <= 0) {
          clearInterval(countdown);
          return 0;
        }
        return prev - 1;
      });
    }, 1000);
  };

  return (
    <main>
      <div className="container-small">
        <h1>Fixhub</h1>
        <h2>新規登録</h2>
        <form onSubmit={handleSignUp}>
          {/* エラーメッセージ表示 */}
          {error && <div className="error-message">{error}</div>}
          {/* メールアドレス入力フィールド */}
          <input
            type="email"
            placeholder="メールアドレス"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            disabled={isCodeVerified} // 認証後無効化
          />
          {isSendingCode && <p>認証コード送信中…</p>} {/* 認証コード送信中メッセージ */}

          {/* 認証コード送信ボタン */}
          {!isCodeVerified && (
            <button
              type="button"
              className={isCodeSent ? "btn btn-border-secondary" : "btn btn-primary"}
              onClick={handleSendCode}
              disabled={isCodeSent}
            >
              認証コードを送信
            </button>
          )}

          {/* 認証コード入力フィールド */}
          {isCodeSent && !isCodeVerified && (
            <>
              <input
                type="text"
                placeholder="認証コード"
                value={authCode}
                onChange={(e) => setAuthCode(e.target.value)}
                required
              />
              <button type="button" className="btn btn-border" onClick={handleVerifyCode}>コード確認</button>
              <p>タイマー: {Math.floor(timer / 60)}:{('0' + (timer % 60)).slice(-2)}</p>
            </>
          )}

          {/* サインアップフィールド */}
          {isCodeVerified && (
            <>
              <input
                type="text"
                placeholder="ハンドル名"
                value={handleName}
                onChange={(e) => setHandleName(e.target.value)}
                required
              />
              <div className="password-container">
                <input
                  type={isPasswordVisible ? 'text' : 'password'}
                  placeholder="パスワード"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
                {/* パスワード表示/非表示アイコン */}
                <span
                  className="material-icons toggle-password"
                  onClick={togglePasswordVisibility}
                >
                  {isPasswordVisible ? 'visibility_off' : 'visibility'}
                </span>
              </div>
              <button type="submit" className="btn btn-primary">会員登録</button>
            </>
          )}
        </form>
      </div>
    </main>
  );
};

export default Register;
