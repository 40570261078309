import { apiClient } from "./apiClient";
import { DraftPostsRequest } from "../types/DraftPostsTypes";

export const fetchDraftPosts = (page: number = 1) => apiClient.get(`/temp-posts/user/?page=${page}`);
export const fetchDraftPost = (id: number) => apiClient.get(`/temp-posts/${id}`);
export const fetchDraftPostForEdit = (id: number) => apiClient.get(`/temp-posts/${id}?isEdit=true`);
export const createDraftPost = (draftPost: DraftPostsRequest) => apiClient.post('/temp-posts', draftPost);
export const createDraftPostAndPostId = (postId: number, draftPost: DraftPostsRequest) => apiClient.post(`/temp-posts/post/${postId}`, draftPost );
export const updateDraftPost = (id: number, draftPost: DraftPostsRequest) => apiClient.put(`/temp-posts/${id}`, draftPost);
export const deleteDraftPost = (id: number) => apiClient.delete(`/temp-posts/${id}`);
export const addFilesToDraftPost = (tempPostId: number, fileIds: number[]) => apiClient.post(`/temp-posts/${tempPostId}/files`, fileIds);
export const fetchRecentDraftPosts = () => apiClient.get('/temp-posts/user/recent');