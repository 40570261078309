import React, { useState, useEffect } from 'react';
import './Profile.css';
import { updateUser, getUserInfo, getSasUrl } from '../../api';

const Profile: React.FC = () => {
    const [handleName, setHandleName] = useState<string>('');
    const [profileImage, setProfileImage] = useState<string>('');
    const [profileName, setProfileName] = useState<string>('');
    const [profileFile, setProfileFile] = useState<File>();
    const [createdAt, setCreatedAt] = useState<string>('');
    const [updatedAt, setUpdatedAt] = useState<string>('');
    const [error, setError] = useState<string>('');
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

    const accessToken = localStorage.getItem('fixhub_auth_ktn');

    const validateHandleName = (name: string) => {
        const regex = /^[\u3040-\u309F\u30A0-\u30FF\uFF66-\uFF9F\u4E00-\u9FFF\u3000-\u303F\uFF01-\uFF5Ea-zA-Z0-9\s]{2,}$/;
        return regex.test(name);
    };

    useEffect(() => {
        const fetchUserInfo = async () => {
            try {
                if (accessToken) {
                    const response = await getUserInfo();
                    const { handleName, createdAt, updatedAt } = response.data;
                    setHandleName(handleName);
                    setCreatedAt(new Date(createdAt).toLocaleString());
                    setUpdatedAt(new Date(updatedAt).toLocaleString());
                    if (response.data.profileImage) {
                        const profileImage = response.data.profileImage;
                        // サーバーから SAS URL を取得
                        const sasResponse = await getSasUrl(profileImage.blobFileName);
                        const sasUrl = sasResponse.url;
                        setProfileName(profileImage.fileName);
                        setProfileImage(sasUrl);
                    }
                } else {
                    setError('アクセストークンが見つかりません。');
                }
            } catch (error) {
                setError('ユーザー情報の取得に失敗しました。');
            }
        };

        if (accessToken) {
            fetchUserInfo();
        }
    }, [accessToken]);

    const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files[0]) {
            const file = e.target.files[0];
            setProfileFile(file);
            const reader = new FileReader();
            reader.onload = () => {
                setProfileImage(reader.result as string);
            };
            reader.readAsDataURL(file);
        }
    };

    const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setHandleName(e.target.value);
        setError('');
    };

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setIsSubmitting(true);
        if (!validateHandleName(handleName)) {
            setError('ハンドル名は2文字以上で、英数字および日本語のみ使用できます。');
            setIsSubmitting(false);
            return;
        }

        try {
            const formData = new FormData();
            formData.append('file', profileFile || '');
            formData.append('handleName', handleName);

            const result = await updateUser(formData);
            if (accessToken) {

                if (result.status === 200) {
                    // alert('プロフィールが更新されました。');
                    // プロフィール情報を再取得
                    // fetchUserInfo();
                } else {
                    alert('プロフィールの更新に失敗しました。');
                }
            } else {
                setError('アクセストークンが見つかりません。');
            }
        } catch (err) {
            alert('サーバーエラーが発生しました。');
            console.error(err);
        } finally {
            setIsSubmitting(false);
        }

        // ページ를 리로드
        window.location.reload();
    };

    return (
        <div className="user-info">
            <h1>プロフィール</h1>
            {error && <div className="error-message">{error}</div>}
            <form onSubmit={handleSubmit}>
                <div className="form-group">
                    <label htmlFor="handle-name">ハンドル名:</label>
                    <input type="text" id="handle-name" value={handleName} onChange={handleNameChange} required />
                </div>
                <div className="form-group">
                    <label htmlFor="profile-image">プロフィールイメージ:</label>
                    <input type="file" id="profile-image" onChange={handleImageChange} accept="image/*" />
                </div>
                {profileImage && (
                    <div className="form-group user-image">
                        <img src={profileImage} alt={profileName} />
                    </div>
                )}
                <div className="form-group">
                    <label htmlFor="registration-date">登録日時:</label>
                    <input type="text" id="registration-date" value={createdAt} readOnly />
                </div>
                <div className="form-group">
                    <label htmlFor="last-update">最終更新日時:</label>
                    <input type="text" id="last-update" value={updatedAt} readOnly />
                </div>
                <div className="form-group center-items">
                    <button className="btn btn-primary" type="submit" disabled={isSubmitting}>
                        {isSubmitting ? '更新中…' : '更新'}
                    </button>
                </div>
            </form>
        </div>
    );
};

export default Profile;