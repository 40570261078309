import React, { useEffect, useState } from 'react';
import { Tag } from '../../types/TagTypes';
import './TagsManagement.css';
import { NewTagForm }  from './TagForm';
import TagItem from './TagItem';
import { fetchTags, deleteTag as deleteTagAPI } from '../../api';

const TagsManagement: React.FC = () => {
    const [tags, setTags] = useState<Tag[]>([]);
    const [isFormVisible, setIsFormVisible] = useState(false);

    useEffect(() => {
        fetchTags().then(result => {
            // Fetch and map tags to tags
            setTags(result.data);
        }).catch(error => console.error('Error fetching the API:', error));
    }, []);

    const addTag = (newTag: Tag) => {
        setTags([...tags, newTag]);
        setIsFormVisible(false);
    };

    const editTag = (updatedTag: Tag) => {
        setTags(tags.map(tag => tag.tagId === updatedTag.tagId ? updatedTag : tag));
    };

    const deleteTag = (id: number) => {
        // check if the user wants to delete the tag
        if (window.confirm('本当に削除しますか？')) {
            // api 호출 추가
            deleteTagAPI(id).then(result => {
                setTags(tags.filter(tag => tag.tagId !== id));
            }).catch(error => console.error('Error deleting the tag:', error));
        }
    };

    return (
        <div className="max-w-3xl mx-auto p-4">
            <div className="items-center mb-4">
                <h2 className="text-xl font-bold">Tags</h2>
                <button
                    onClick={() => setIsFormVisible(true)}
                    className="px-3 py-1 btn-primary text-white rounded hover:bg-green-600"
                >
                    New tag
                </button>
            </div>

            {isFormVisible && (
                <NewTagForm
                    onSubmit={addTag}
                    onCancel={() => setIsFormVisible(false)}
                />
            )}

            <div className="tags-container rounded-lg shadow">
                {/* tags count */}
                <div className="text-secondary font-medium">
                    {tags.length} tags
                </div>
                {tags.map(tag => (
                    <TagItem
                        key={tag.tagId}
                        tag={tag}
                        onEdit={editTag}
                        onDelete={deleteTag}
                    />
                ))}
            </div>
        </div>
    );
};

export default TagsManagement;