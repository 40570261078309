import React, { createContext, useContext, useState, useEffect } from 'react';
import jwtDecode from 'jwt-decode';
import { removeCookie } from '../utils/cookieUtils';

interface AuthContextProps {
  isAuthenticated: boolean;
  isAdmin: boolean;
  isLoading: boolean;
  loginAuth: (token: string) => void;
  logoutAuth: () => void;
}

const AuthContext = createContext<AuthContextProps | undefined>(undefined);

interface AuthProviderProps {
  children: React.ReactNode;
}

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const loginAuth = (token: string) => {
    setIsAuthenticated(true);
    
    // ロール情報を取得
    const decodedToken: any = jwtDecode(token);
    const roles = decodedToken["http://schemas.microsoft.com/ws/2008/06/identity/claims/role"] || [];

    setIsAdmin(roles === 'Admin');
    setIsLoading(false);
  };

  const logoutAuth = () => {
    localStorage.removeItem('fixhub_auth_ktn');
    removeCookie('fixhub_rfr_ktn');
    
    setIsAuthenticated(false);
    setIsAdmin(false);
    setIsLoading(false);
  };

  useEffect(() => {
    const token = localStorage.getItem('fixhub_auth_ktn');
    if (token) {
      loginAuth(token);
    } else {
      setIsLoading(false);
    }
  }, []);

  return (
    <AuthContext.Provider value={{ isAuthenticated, isAdmin, isLoading, loginAuth, logoutAuth }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};