import { apiClient } from './apiClient';
import { UserRegistRequest, changePasswordRequest, withdrawalRequest } from '../types/UserTypes';

// ログインAPIの呼び出し
export const login = async (email: string, password: string) => 
  apiClient.post('/users/login', { Email: email, Password: password });

// 認証コード送信APIの呼び出し
export const sendVerificationCode = (email: string) => {
  return apiClient.post('/users/send-code', { Email: email });
}

// 認証コード確認APIの呼び出し
export const verifyCode = async (email: string, code: string) => 
  apiClient.post('/users/verify-code', { Email: email, Code: code });

// サインアップAPIの呼び出し
export const signUp = async (userinfo: UserRegistRequest) => 
  apiClient.post('/users', userinfo);

// ユーザー情報を取得するAPIの呼び出し
export const getUserInfo = async () => apiClient.get('/users');

// ユーザー情報を更新するAPIの呼び出し
export const updateUser = (formData: FormData) => {
  return apiClient.putNoJson('/users', formData);
}

// メールアドレスの重複確認APIの呼び出し
export const checkEmail = async (email: string) => 
  apiClient.post('/users/check-email', { Email: email });

// パスワード変更APIの呼び出し
export const changePassword = async (changePasswordRequest: changePasswordRequest) => 
  apiClient.patch('/users/change-password', changePasswordRequest);

// パスワードリセットリクエストを送信APIの呼び出し
export const requestPasswordReset = async (email: string) => 
  apiClient.post('/users/request-password-reset', { Email: email });

// パスワードリセットAPIの呼び出し
export const resetPassword = (token: string, newPassword: string) => {
  return apiClient.patch('/users/reset-password', { token, newPassword });
};

// ユーザー退会APIの呼び出し
export const withdrawUser = async (withdrawalRequest: withdrawalRequest) => 
  apiClient.post('/users/withdraw', withdrawalRequest);