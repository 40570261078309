import React from 'react';

interface PostTypeSelectorProps {
  postType: 'normal' | 'error';
  setPostType: (type: 'normal' | 'error') => void;
}

const PostTypeSelector: React.FC<PostTypeSelectorProps> = ({ postType, setPostType }) => (
  <div className="mb-2">
    <button
      className={`btn ${postType === 'normal' ? 'btn-primary' : 'btn-secondary'} mr-2`}
      onClick={() => setPostType('normal')}
    >
      一般投稿
    </button>
    <button
      className={`btn ${postType === 'error' ? 'btn-primary' : 'btn-secondary'}`}
      onClick={() => setPostType('error')}
    >
      エラーレポート
    </button>
  </div>
);

export default PostTypeSelector;