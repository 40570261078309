import React from 'react';
import { ERROR_STATUS, ErrorStatus } from '../../../constants/ErrorStatus';

interface ErrorFieldsProps {
  errorFields: {
    environment: string;
    message: string;
    status: ErrorStatus;
  };
  setErrorFields: (fields: {
    environment: string;
    message: string;
    status: ErrorStatus;
  }) => void;
}

const ErrorFields: React.FC<ErrorFieldsProps> = ({ errorFields, setErrorFields }) => (
  <>
    <div className="mb-2">
      <label htmlFor="errorEnvironment" className="block mb-2">エラー発生環境</label>
      <input
        type="text"
        id="errorEnvironment"
        value={errorFields.environment}
        onChange={(e) => setErrorFields({ ...errorFields, environment: e.target.value })}
        className="input-field w-full"
      />
    </div>
    <div className="mb-2">
      <label htmlFor="errorMessage" className="block mb-2">エラーメッセージ</label>
      <input
        type="text"
        id="errorMessage"
        value={errorFields.message}
        onChange={(e) => setErrorFields({ ...errorFields, message: e.target.value })}
        className="input-field w-full"
      />
    </div>
    <div className="mb-2">
      <label htmlFor="errorStatus" className="block mb-2">エラーステータス</label>
      <select
        id="errorStatus"
        value={errorFields.status}
        onChange={(e) => setErrorFields({ ...errorFields, status: e.target.value as ErrorStatus })}
        className="input-field w-full"
      >
        {Object.values(ERROR_STATUS).map((status) => (
          <option key={status} value={status}>{status}</option>
        ))}
      </select>
    </div>
  </>
);

export default ErrorFields;