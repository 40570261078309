import React, { useEffect, useState } from 'react';
import { fetchTeamActivities } from '../../../api';
import { TeamActivitiesType } from '../../../types/teamActivityTypes';

const TeamActivityFeed: React.FC = () => {
  const [activities, setActivities] = useState<TeamActivitiesType[]>([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  useEffect(() => {
    const fetchActivities = async () => {
      try {
        const response = await fetchTeamActivities(page);
        setActivities(response.data);
        setTotalPages(response.pagination.totalPages); // Set totalPages from the response
      } catch (error) {
        console.error('Failed to fetch team activities:', error);
      }
    }

    fetchActivities();
  }, [page]);

  const formatActivityMessage = (activity: TeamActivitiesType) => {
    switch (activity.activityType) {
      case 'Error Resolved':
        return (
          <span>
            {activity.activityDetails.userName}さんが「
            {activity.activityDetails.postTitle}
            」を<span className="bold">解決</span>しました。
          </span>
        );
      case 'Feedback Created':
        return (
          <span>
            {activity.activityDetails.userName}さんが「
            {activity.activityDetails.postTitle}
            」に<span className="bold">コメント</span>を追加しました。
          </span>
        );
      case 'Post Created':
        return (
          <span>
            {activity.activityDetails.userName}さんが新しい投稿「
            <span className="bold">{activity.activityDetails.postTitle}</span>
            」を作成しました。
          </span>
        );
      default:
        return '';
    }
  };

  return (
    <div className="dashboard-item">
      <h3>チーム活動フィード</h3>
      {activities.length === 0 ? (
        <p>チーム活動データがありません。</p>
      ) : (
        <ul className="team-feed">
          {activities.map(activity => (
            <li key={activity.activityId}>{formatActivityMessage(activity)}</li>
          ))}
        </ul>
      )}
      {/* Pagination Controls */}
      <div className="pagination-next">
        {page > 1 && (
          <button onClick={() => setPage(page - 1)}>&lt;</button>
        )}
        <span>{page}</span>
        {page < totalPages && (
          <button onClick={() => setPage(page + 1)}>&gt;</button>
        )}
      </div>
    </div>
  );
};

export default TeamActivityFeed;
