import React from 'react';
import MDEditor from '@uiw/react-md-editor';
import { uploadFile, getSasUrl } from '../../../api';
import { FILE_CATEGORY } from '../../../constants/FileCategory';
import { ALLOWED_FILE_TYPES } from '../../../constants/AllowedFileType';  // 許可するファイルの種類

interface PostFormProps {
  title: string;
  setTitle: (title: string) => void;
  content: string;
  setContent: (content: string) => void;
  setFileIds: (fileIds: ((prevFileIds: number[]) => number[])) => void;
}

const PostForm: React.FC<PostFormProps> = ({ title, setTitle, content, setContent, setFileIds }) => {
  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
    const file = event.dataTransfer.files[0];
    if (file) {
      const formData = new FormData();
      formData.append('File', file);
      formData.append('FileCategory', FILE_CATEGORY.PostAttachment.toString());

      // ファイルのタイプを確認する
      if (!ALLOWED_FILE_TYPES.includes(file.type)) {
        alert('許可されていないファイル形式です。');
        return;
      }

      uploadFile(formData)
        .then(async (result) => {
          const fileId = result.data.fileId;
          const fileName = result.data.fileName;
          console.log('ファイルアップロード成功:', result.data);

          // イメージファイルの場合、イメージを表示
          if (file.type.startsWith('image/')) {
            // サーバーから SAS URL を取得
            const sasResponse = await getSasUrl(result.data.blobFileName);
            const sasUrl = sasResponse.url;
            setContent(`${content}\n![${fileName}](${sasUrl})`);
          } else {
            // その他のファイルの場合、リンクを表示
            const sasResponse = await getSasUrl(result.data.blobFileName);
            const sasUrl = sasResponse.url;
            setContent(`${content}\n[${fileName}](${sasUrl})`);
          }

          setFileIds((prevFileIds: number[]) => [...prevFileIds, fileId]);
        })
        .catch(error => console.error('ファイルアップロードエラー:', error));
    }
  };

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
  };

  return (
    <>
      <div className="mb-2">
        <label htmlFor="title" className="block mb-2">タイトル<span className="text-danger">*</span></label>
        <input
          type="text"
          id="title"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          className="input-field w-full"
          required
        />
      </div>

      <div className="mb-2">
        <label className="block mb-2">内容</label>
        <div onDrop={handleDrop} onDragOver={handleDragOver}>
          <MDEditor
            value={content}
            onChange={(value) => setContent(value || '')}
          />
        </div>
      </div>
    </>
  );
};

export default PostForm;
