import React, { useEffect, useState, useRef } from 'react';
import { Chart } from 'chart.js/auto';
import { fetchErrorReportsCountLast10Days } from '../../../api';
import { ErrorReportData } from '../../../types/ErrorReportsTypes';

const ErrorTrendChart: React.FC = () => {
  const [data, setData] = useState<ErrorReportData[]>([]);
  const chartRef = useRef<Chart | null>(null);

  useEffect(() => {
    fetchErrorReportsCountLast10Days()
    .then(response => setData(response.data))
    .catch(error => console.error(error));
  }, []);

  useEffect(() => {
    if (data.length > 0) {
      const labels = Array.from({ length: 10 }, (_, i) => {
        const date = new Date();
        date.setDate(date.getDate() - (9 - i));
        return date.toLocaleDateString('en-US', { month: '2-digit', day: '2-digit' });
      });

      const chartData = labels.map(label => {
        const report = data.find(d => new Date(d.date).toLocaleDateString('en-US', { month: '2-digit', day: '2-digit' }) === label);
        return report ? report.count : 0;
      });

      if (chartRef.current) {
        chartRef.current.destroy();
      }

      chartRef.current = new Chart('errorTrendChart', {
        type: 'line',
        data: {
          labels,
          datasets: [
            {
              label: 'エラー発生数',
              data: chartData,
            },
          ],
        },
        options: {
          scales: {
            y: {
              beginAtZero: true,
              ticks: {
                callback: function(value) {
                  return Number.isInteger(value) ? value : null;
                }
              }
            },
          },
        },
      });
    }
  }, [data]);

  useEffect(() => {
    return () => {
      if (chartRef.current) {
        chartRef.current.destroy();
      }
    };
  }, []);

  return (
    <div className="dashboard-item">
      <h3>エラートレンド分析</h3>
      <div className="chart"><canvas id="errorTrendChart"></canvas></div>
    </div>
  );
};

export default ErrorTrendChart;