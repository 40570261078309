import React, { useState } from 'react';
import { Tag, TagRequest } from '../../types/TagTypes';
import ColorPicker from './ColorPicker';
import { createTag, updateTag } from '../../api';

interface TagFormProps {
    tag?: Tag;
    onSubmit: (tag: Tag) => void;
    onCancel: () => void;
    isEditMode: boolean;
}

const TagForm: React.FC<TagFormProps> = ({ tag, onSubmit, onCancel, isEditMode }) => {
    const id = isEditMode ? tag?.tagId || undefined : undefined;
    const [name, setName] = useState(tag?.name);
    const [description, setDescription] = useState(tag?.content || '');
    const [color, setColor] = useState(tag?.color || '#1D76DB');
    const [showColorPicker, setShowColorPicker] = useState(false);

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        
        if (!name) {
            alert('タグ名を入力してください。');
            return;
        }

        if (!color) {
            alert('色を選択してください。');
            return;
        }

        const newTag: TagRequest = { 
            name: name, 
            content: description, 
            color: color 
        };

        try {
            let result;
            if (isEditMode) {
                if (id !== undefined) {
                    result = await updateTag(id, newTag);   // updateTag APIの呼び出し
                } else {
                    throw new Error('タグIDが見つかりません。'); 
                }
            } else {
                result = await createTag(newTag);     // createTag APIの呼び出し
            }

            if (result.type === 'error') {
                console.log(result.message);
                alert('エラーが発生しました。');
                return;
            } else if (result.type === 'success') {
                onSubmit(result.data); // サーバーから返された結果を親コンポーネントに渡す
                onCancel(); // フォームを閉じる
            }
            onCancel(); // フォームを閉じる
        } catch (error) {
            console.error('Error submitting the tag:', error);
        }
    };

    return (
        <form onSubmit={handleSubmit} className={`form-container ${isEditMode ? 'bg-primary' : 'bg-secondary'}`}>
            <div className="mb-2">
                <div className="tag-badge" style={{ backgroundColor: color }}>
                    {name || 'Tag preview'}
                </div>
            </div>
            <div className="tags-form">
                <div className="form-group tag-name">   
                    <label htmlFor="tag-name">Tag name</label>
                    <input
                        id="tag-name"
                        type="text"
                        placeholder="Tag name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        className="input-field"
                    />
                </div>
                <div className="form-group color-picker-wrapper">
                    <label htmlFor="tag-color">Color</label>
                    <input
                        id="tag-color"
                        type="text"
                        value={color}
                        placeholder='Color'
                        onClick={() => setShowColorPicker(!showColorPicker)}
                        onChange={(e) => setColor(e.target.value)}
                        className="input-field color-input"
                    />
                    {showColorPicker && (
                        <div className="color-picker-container">
                            <ColorPicker color={color} onChange={(newColor) => {
                                setColor(newColor);
                                setShowColorPicker(false);
                            }} />
                        </div>
                    )}
                </div>
                <div className="form-group description">
                    <label htmlFor="tag-description">Description</label>
                    <input
                        id="tag-description"
                        type="text"
                        placeholder="Description (optional)"
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        className="input-field"
                    />
                </div>
            </div>
            <div className="flex justify-end">
                <button type="button" onClick={onCancel} className="btn btn-secondary mr-2">
                    Cancel
                </button>
                <button type="submit" className="btn btn-primary">
                    {isEditMode ? 'Save changes' : 'Create tag'}
                </button>
            </div>
        </form>
    );
};

export const NewTagForm = (props: Omit<TagFormProps, 'isEditMode'>) => <TagForm {...props} isEditMode={false} />;
export const EditTagForm = (props: Omit<TagFormProps, 'isEditMode'>) => <TagForm {...props} isEditMode={true} />;

export default TagForm;