import React, { useState } from 'react';
import { Tag } from '../../types/TagTypes';
import { EditTagForm } from './TagForm';

interface TagItemProps {
    tag: Tag;
    onEdit: (tag: Tag) => void;
    onDelete: (id: number) => void;
}

const TagItem: React.FC<TagItemProps> = ({ tag, onEdit, onDelete }) => {
    const [isEditing, setIsEditing] = useState(false);

    const handleEdit = () => setIsEditing(true);
    const handleCancel = () => setIsEditing(false);
    const handleSave = (updatedTag: Tag) => {
        onEdit(updatedTag);
        setIsEditing(false);
    };

    return (
        <div className="tag-item" id={`tag-id-${tag.tagId}`} >
            <div className="items-center p-2 border-secondary">
                <div>
                    <span className="tag-badge mr-2" style={{ backgroundColor: tag.color }}>
                        {tag.name}
                    </span>
                    <span className="ml-2 text-secondary font-small">{tag.content}</span>
                </div>
                <div>
                    {!isEditing && (
                        <button onClick={handleEdit} className="btn btn-secondary mr-2">Edit</button>
                    )}
                    <button onClick={() => onDelete(tag.tagId)} className="btn btn-delete">Delete</button>
                </div>
            </div>

            {isEditing && (
                <div className="mt-2">
                    <EditTagForm
                        tag={tag}
                        onSubmit={handleSave}
                        onCancel={handleCancel}
                    />
                </div>
            )}
        </div>
    );
};

export default TagItem;