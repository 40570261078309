import React from 'react';
import { useNavigate } from 'react-router-dom';
const WithdrawConfirmation: React.FC = () => {
  const navigate = useNavigate();

  const confirmWithdrawal = () => {
    navigate('/account/delete'); // Navigate to the account deletion page
  };

  const cancelWithdrawal = () => {
    navigate(-1); // Go back to the previous page
  };

  return (
    <main>
      <div className="container">
        <div className="center-items">
          <h1>退会確認</h1>
          <h3>本当に退会しますか？</h3>
          <h3>退会すると、以下の内容が適用されます。</h3>
          <ul className="form-container">
            <li className="bg-primary p-2">
              退会後、お客様の登録された個人情報は30日後に完全に削除されます。
              <p className="text-secondary font-small">メールアドレス、ハンドル名、プロフィールイメージなどの情報</p>
            </li>
            <li className="bg-primary p-2">退会後も、お客様が作成したエラーや知識共有の投稿は匿名化されシステム内に残ります。</li>
            <li className="bg-primary p-2">チームメンバーとの協力中に残された記録や活動履歴は維持され、投稿者の名前は匿名化されます。</li>
          </ul>
          <div className="contact">
            退会後もサポートが必要な場合は、カスタマーサポートチームにお問い合わせいただけます。
            <p className="text-secondary font-small">
              お問い合わせの際には、退会前のメールアドレスをお知らせください。example@example.com
            </p>
          </div>
        </div>

        <div className="items-center">
          <button className="btn btn-danger" onClick={confirmWithdrawal}>
            退会する
          </button>
          <button className="btn btn-secondary" onClick={cancelWithdrawal}>
            キャンセル
          </button>
        </div>
      </div>
    </main>
  );
};

export default WithdrawConfirmation;
