import React, { useState, useEffect } from 'react';
import { searchPosts, fetchTags } from '../../api';
import { Post, PostSearchRequest } from '../../types/PostTypes';
import { Tag } from '../../types/TagTypes';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import TagSelector from './components/TagSelector';
import { ErrorStatus, ERROR_STATUS } from '../../constants/ErrorStatus';
import './PostList.css';

const PostList: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [posts, setPosts] = useState<Post[]>([]);
  const [searchRequest, setSearchRequest] = useState<PostSearchRequest>({});
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [tags, setTags] = useState<Tag[]>([]);
  const [tagIds, setTagIds] = useState<number[]>([]);
  const [isOwnPost, setIsOwnPost] = useState(false);
  const [orderBy, setOrderBy] = useState<string>('newest');
  const [isErrorReport, setIsErrorReport] = useState<boolean | undefined>(undefined);
  const [errorStatus, setErrorStatus] = useState<ErrorStatus | undefined>('');
  const [keyword, setKeyword] = useState<string>('');
  const [isShow, setIsShow] = useState(false);  // 期間選択の表示制御
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [isScrollTopVisible, setIsScrollTopVisible] = useState(false);  // スクロールトップボタンの表示制御
  const [loading, setLoading] = useState(false);
  const [showOwnPostsOnly, setShowOwnPostsOnly] = useState(false);

  useEffect(() => {
    fetchTags()
      .then((result) => setTags(result.data))
      .catch((error) => console.error('タグ取得エラー:', error));

    const handleScroll = () => {
      setIsScrollTopVisible(window.scrollY > 200); // Show if scrolled down 200px
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  useEffect(() => {
    setShowOwnPostsOnly(location.state?.showOwnPostsOnly || false);
    if (showOwnPostsOnly) {
      setIsOwnPost(true);
      setLoading(true);
      setSearchRequest((prev) => ({
        ...prev,
        isOwnPost: true,
      }));
      setPage(1);
    }
  }, [location.state, showOwnPostsOnly]);

  useEffect(() => {
    if (!loading && showOwnPostsOnly) return;

    // 投稿検索
    const fetchPosts = async () => {
      try {
        const response = await searchPosts(searchRequest, page);
        setPosts(response.data);
        setTotalPages(response.pagination.totalPages);
        setTotalCount(response.pagination.totalCount);
      } catch (error) {
        console.error(error);
        alert('エラーが発生しました。');
        navigate('/login');
      }
    };

    if (loading && showOwnPostsOnly) {
      const fetchPosts = async () => {
        try {
          const response = await searchPosts(searchRequest, page);
          setPosts(response.data);
          setTotalPages(response.pagination.totalPages);
          setTotalCount(response.pagination.totalCount);
        } catch (error) {
          console.error(error);
          alert('エラーが発生しました。');
          navigate('/login');
        } finally {
          setLoading(false);
        }
      };

      fetchPosts();
    } else {
      setLoading(true);
      fetchPosts();
    }

  }, [page, searchRequest, loading, showOwnPostsOnly, navigate]);

  useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent) => {
      if (event.key === 'Enter' && keyword) {
        setSearchRequest((prev) => ({
          ...prev,
          keyword,
        }));
        setPage(1); // Reset to page 1 on new search
      }
    };

    window.addEventListener('keypress', handleKeyPress);
    return () => {
      window.removeEventListener('keypress', handleKeyPress);
    };
  }, [keyword]);

  // キーワード検索
  const handleSearchClick = () => {
    setSearchRequest((prev) => ({
      ...prev,
      keyword,
    }));
    setPage(1); // Reset to page 1 on new search
  };

  // 自分の投稿検索
  const toggleOwnPosts = () => {
    setIsOwnPost(!isOwnPost);
    setSearchRequest((prev) => ({
      ...prev,
      isOwnPost: !isOwnPost,
    }));
    setPage(1); // Reset to page 1 on search
  };

  // 整列フィルター設定
  const handleOrderChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const order = event.target.value;
    setOrderBy(order);
    setSearchRequest((prev) => ({
      ...prev,
      orderBy: order,
    }));
    setPage(1); // Reset to page 1 on search
  };

  // 投稿タイプ設定
  const handlePostTypeChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const type = event.target.value;
    const isError = type === 'errorReport' ? true : type === 'general' ? false : undefined;
    setIsErrorReport(isError);
    setSearchRequest((prev) => ({
      ...prev,
      isErrorReport: isError,
    }));
    setPage(1); // Reset to page 1 on search
  };

  // エラー報告書ステータス設定
  const handleErrorStatusChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const status = event.target.value as ErrorStatus;
    setErrorStatus(status);
    setSearchRequest((prev) => ({
      ...prev,
      errorStatus: status,
    }));
    setPage(1); // Reset to page 1 on search
  };

  // 期間設定
  const handleDateChange = (field: 'startDate' | 'endDate', value: string) => {
    setStartDate(field === 'startDate' ? value : startDate);
    setEndDate(field === 'endDate' ? value : endDate);
    setSearchRequest((prev) => ({
      ...prev,
      [field]: value,
    }));
    setPage(1); // Reset to page 1 on search
  };

  // キーワード設定
  const handleKeywordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const input = event.target.value.slice(0, 255);
    setKeyword(input);
  };

  // タグ選択
  const handleTagSelection = (selectedTags: number[]) => {
    setTagIds(selectedTags);
    setSearchRequest((prev) => ({
      ...prev,
      tagIds: selectedTags,
    }));
    setPage(1); // Reset to page 1 on search
  };

  // 検索リセット
  const resetFilters = () => {
    setIsOwnPost(false);
    setOrderBy('newest');
    setIsErrorReport(undefined);
    setErrorStatus('');
    setStartDate('');
    setEndDate('');
    setKeyword('');
    setTagIds([]);
    setPage(1);
    setSearchRequest({});
  }

  // ページ変更
  const handlePageChange = (newPage: number) => {
    setPage(newPage);
    window.scrollTo(0, 0);
  }

  // Author名表示（退会ユーザーは「退会済みユーザー」と表示）
  const getAuthorName = (author: Post['author']) => {
    return author?.isWithdrawn || author === undefined ? '退会済みユーザー' : author?.handleName;
  };

  return (
    <main>
      <div className="container">
        <div className="search-group">
          <input
            type="text"
            placeholder="検索キーワードを入力"
            value={keyword}
            onChange={handleKeywordChange}
            className="input-field w-full"
          />
          <button className="btn btn-primary" onClick={handleSearchClick}>検索</button>
        </div>

        {/* タグ選択 */}
        <div className="tag-selector">
          <TagSelector tags={tags} selectedTags={tagIds} setSelectedTags={handleTagSelection} />
        </div>

        <div className='filter-container'>
          {/* 自分の投稿検索 */}
          <button onClick={toggleOwnPosts} className={`mt-2 btn ${isOwnPost ? 'btn-secondary' : 'btn-border-secondary'}`}>
            自分の投稿のみ
          </button>

          <div className="filter-group">
            {/* 期間選択 */}
            {isShow && (
              <div className="date-filter">
                <input
                  type="date"
                  value={startDate}
                  onChange={(e) => handleDateChange('startDate', e.target.value)}
                />
                <span>〜</span>
                <input
                  type="date"
                  value={endDate}
                  onChange={(e) => handleDateChange('endDate', e.target.value)}
                />
              </div>
            )}
            <button className={`mt-2 btn ${(startDate === '' && endDate === '') ? 'btn-border-secondary' : 'btn-secondary'}`} onClick={() => setIsShow(!isShow)}>期間選択</button>

            {/* 投稿フィルター */}
            <select onChange={handlePostTypeChange} value={isErrorReport === true ? 'errorReport' : isErrorReport === false ? 'general' : 'all'}>
              <option value="all">全ての投稿</option>
              <option value="general">一般投稿</option>
              <option value="errorReport">エラー報告書</option>
            </select>

            {/* エラー報告書のステータスフィルター */}
            {isErrorReport && (
              <select onChange={handleErrorStatusChange} value={errorStatus}>
                <option value="">全てのステータス</option>
                <option value={ERROR_STATUS.UNSOLVED}>{ERROR_STATUS.UNSOLVED}</option>
                <option value={ERROR_STATUS.IN_PROGRESS}>{ERROR_STATUS.IN_PROGRESS}</option>
                <option value={ERROR_STATUS.SOLVED}>{ERROR_STATUS.SOLVED}</option>
              </select>
            )}

            {/* 整列フィルター */}
            <select onChange={handleOrderChange} value={orderBy}>
              <option value="newest">新しい順</option>
              <option value="oldest">古い順</option>
            </select>
          </div>
        </div>
        <div className="filter-reset">
          {/* 検索リセット */}
          <button
            className="btn-border-secondary"
            onClick={() => {
              resetFilters();
            }}
          >
            検索リセット
          </button>
        </div>
      </div>

      {/* 検索結果 */}
      <div className="search-result">
        <p>検索結果: {totalCount}件</p>
      </div>

      {/* 投稿リスト*/}
      <div className="container mt-0">
        {posts.length === 0 && <p>検索結果がありません。</p>}
        <ul className="post-list">
          {posts.map((post) => (
            <li className="postback-item" key={post.postId}>
              <Link to={`/posts/${post.postId}`} target="_blank" rel="noopener noreferrer">
                <div className="tag-container">
                  {post.tags.map((tag) => (
                    <span key={tag.tagId} className="tag" style={{ backgroundColor: tag.color }}>
                      {tag.name}
                    </span>
                  ))}
                </div>

                <h3 className="title">{post.title}</h3>
                <p className="content">
                  {post.content || '（内容なし）'}
                </p>
                <div className="post-meta">
                  <p className="assignee text-secondary">{getAuthorName(post.author)}</p>
                  <p className="created-date text-secondary">{new Date(post.createdAt).toLocaleDateString()}</p>
                </div>
              </Link>
            </li>
          ))}
        </ul>

        {/* ページネーション */}
        <div className="pagination">
          {[...Array(totalPages)].map((_, index) => (
            <button
              key={index + 1}
              onClick={() => handlePageChange(index + 1)}
              className={page === index + 1 ? 'active' : ''}
            >
              {index + 1}
            </button>
          ))}
        </div>
      </div>

      {/* スクロールトップボタン */}
      <button
        className={`scroll-to-top ${isScrollTopVisible ? 'visible' : ''}`}
        onClick={scrollToTop}
      >
        ↑
      </button>
    </main>
  );
};

export default PostList;
