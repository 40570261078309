import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { changePassword } from '../../api/auth';
import { useAuth } from '../../contexts/AuthContext';

const validatePassword = (password: string) => password.length >= 8 && /^(?=.*[a-zA-Z])(?=.*\d).+$/.test(password);

const ChangePassword: React.FC = () => {
  const navigate = useNavigate();
  const { logoutAuth } = useAuth();
  const [oldPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showPasswords, setShowPasswords] = useState({
    current: false,
    new: false,
    confirm: false
  });
  const [error, setError] = useState<string>('');

  const handlePasswordToggle = (field: 'current' | 'new' | 'confirm') => {
    setShowPasswords(prev => ({ ...prev, [field]: !prev[field] }));
  };

  const handleLogout = () => {
    logoutAuth();
    navigate('/login');
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!validatePassword(newPassword)) {
      setError('パスワードは8文字以上で、英数字を含めてください。');
      return;
    }
    if (newPassword !== confirmPassword) {
      setError('パスワードが一致しません。');
      return;
    }

    try {
      await changePassword({ oldPassword, newPassword }).then(() => {
        setCurrentPassword('');
        setNewPassword('');
        setConfirmPassword('');
        alert('パスワードが変更されました。');
        handleLogout();
      }).catch((error) => {
        if (error.response && error.response.data) {
          const { identifier } = error.response.data;
          if (identifier === "INVALID_PASSWORD") {
            setError('現在のパスワードが間違っています。');
            return;
          } else if (identifier === "USER_NOT_FOUND") {
            setError('ユーザーが見つかりません。');
            return;
          } else {
            setError('パスワードの変更に失敗しました。');
            return;
          }
        }
        setError('パスワードの変更に失敗しました。');
      });
    } catch (error) {
      setError('パスワードの変更に失敗しました。');
    }
  };

  return (
    <main>
      <div className="container-small">
        <form onSubmit={handleSubmit}>
          <h1>パスワード変更</h1>
          {error && <div className="error-message">{error}</div>}
          <div className="password-container">
            <input
              type={showPasswords.current ? 'text' : 'password'}
              placeholder="現在のパスワード"
              value={oldPassword}
              onChange={(e) => setCurrentPassword(e.target.value)}
            />
            <span
              className="material-icons"
              onClick={() => handlePasswordToggle('current')}
            >
              {showPasswords.current ? 'visibility_off' : 'visibility'}
            </span>
          </div>
          <div className="password-container">
            <input
              type={showPasswords.new ? 'text' : 'password'}
              placeholder="新しいパスワード"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
            />
            <span
              className="material-icons"
              onClick={() => handlePasswordToggle('new')}
            >
              {showPasswords.new ? 'visibility_off' : 'visibility'}
            </span>
          </div>

          <div className="password-container">
            <input
              type={showPasswords.confirm ? 'text' : 'password'}
              placeholder="新しいパスワードの確認"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
            <span
              className="material-icons"
              onClick={() => handlePasswordToggle('confirm')}
            >
              {showPasswords.confirm ? 'visibility_off' : 'visibility'}
            </span>
          </div>
          <button type="submit" className="btn btn-primary">パスワードを変更する</button>
        </form>
      </div>
    </main>
  );
};

export default ChangePassword;
