import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { fetchRecentDraftPosts } from '../../../api';
import { PostUserResponse } from '../../../types/PostTypes';

const UserPostsList: React.FC = () => {
  const navigate = useNavigate();
  const [posts, setPosts] = useState<PostUserResponse[]>([]);

  useEffect(() => {
    fetchRecentDraftPosts().then((response) => setPosts(response.data));
  }, []);

  const viewAllPosts = () => {
    navigate('/posts-list', { state: { showOwnPostsOnly: true } });
  };

  return (
    <>
      {posts.length === 0 ? (
        <div className="text-center">
          <p>作成した投稿がありません。</p>
        </div>
      ) : (
        <>
          <ul className="list">
            {posts.map((post) => (
              <li className="postback-item" key={post.postId || post.tempPostId}>
                <div onClick={() => window.location.href = `/posts/${post.postId}`}>
                  <strong>{post.title}</strong>
                  <div className="status">
                    {post.postId ? "登録完了" : "未登録"}
                  </div>
                  {post.isErrorReport && <div className="status">{post.errorReport?.errorStatus}</div>}
                </div>
                {!post.postId && (
                  <button className="btn btn-border-secondary" onClick={() => window.location.href = `/posts/create?draftId=${post.tempPostId}`}>
                    続いて作成
                  </button>
                )}
              </li>
            ))}
          </ul>
          <div className="flex justify-end gap-2">
            <button className="btn btn-primary mr-2" onClick={viewAllPosts}>自分の投稿すべてを見る</button>
            <button className="btn btn-border" onClick={() => window.location.href = '/drafts'}>一時保存した投稿を見る</button>
          </div>
        </>
      )}
    </>
  );
};

export default UserPostsList;
