import React, { useState, useEffect } from 'react';
import { getUserInfo, withdrawUser } from '../../api';
import { useAuth } from '../../contexts/AuthContext';

const Withdraw: React.FC = () => {
  const [email, setEmail] = useState('');
  const [handleName, setHandleName] = useState('');
  const [password, setPassword] = useState('');
  const [reason, setReason] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [showPasswords, setShowPasswords] = useState(false);
  const { logoutAuth } = useAuth();

  useEffect(() => {
    // Fetch user information on component load
    const fetchUserInfo = async () => {
      try {
        const response = await getUserInfo();
        setEmail(response.data.email);
        setHandleName(response.data.handleName);
      } catch (error) {
        console.error('Failed to fetch user info:', error);
      }
    };
    fetchUserInfo();
  }, []);

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
    setPasswordError('');
  };

  const handleSubmit = async (e: React.FormEvent) => {
    // 退会するのか確認
    var confirmWithdraw = window.confirm('本当に退会しますか？');
    if (!confirmWithdraw) {
      return;
    }
    
    e.preventDefault();
    if (!password) {
      setPasswordError('パスワードを入力してください。');
      return;
    }

    try {
      // 退会処理
      var withdrawalRequest = {
        password: password,
        reason: reason
      };
      await withdrawUser(withdrawalRequest).then(() => {
        alert('退会が完了しました。');
        logoutAuth();
      }).catch((error) => {
        if (error.response && error.response.data) {
          const { identifier } = error.response.data;
          if (identifier === "INVALID_PASSWORD") {
            setPasswordError('パスワードが間違っています。');
            return;
          } else {
            console.error('Failed to withdraw user:', error);
            return;
          }
        }
      });
    } catch (error) {
      console.error('Failed to withdraw user:', error);
    }
  };

  return (
    <main className="container-small">
      <h1>ユーザー退会</h1>
      <form onSubmit={handleSubmit} noValidate>
        <div className="form-container">
          <table>
            <tbody>
              <tr>
                <td>メールアドレス:</td>
                <td>{email}</td>
              </tr>
              <tr>
                <td>ユーザー名:</td>
                <td>{handleName}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="password-container">
          <input
            type={showPasswords ? 'text' : 'password'}
            id="password"
            name="password"
            placeholder="パスワード"
            value={password}
            onChange={handlePasswordChange}
            required
          />
          <span
              className="material-icons"
              onClick={() => setShowPasswords(!showPasswords)}
            >
              {showPasswords ? 'visibility_off' : 'visibility'}
            </span>
        </div>
        {passwordError && <div className="error-message">{passwordError}</div>}
        <div className="textarea-field">
          <textarea
            id="reason"
            name="reason"
            rows={4}
            placeholder="退会理由"
            value={reason}
            onChange={(e) => setReason(e.target.value)}
          />
        </div>
        <div>
          <button type="submit" className="btn btn-danger">退会</button>
        </div>
      </form>
    </main>
  );
};

export default Withdraw;
