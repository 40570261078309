import React from 'react';
import { Tag } from '../../../types/TagTypes';

interface TagSelectorProps {
  tags: Tag[];
  selectedTags: number[];
  setSelectedTags: (tags: number[]) => void;
}

const TagSelector: React.FC<TagSelectorProps> = ({ tags, selectedTags, setSelectedTags }) => {
  const handleTagToggle = (tagId: number) => {
    const newSelectedTags = selectedTags.includes(tagId)
      ? selectedTags.filter((id) => id !== tagId)
      : [...selectedTags, tagId];
    setSelectedTags(newSelectedTags);
  };

  return (
    <div className="mb-2 tag-selector">
      <div className="flex flex-wrap">
        {tags.map((tag) => (
          <label key={tag.tagId} className="mr-2 mb-2">
            <input
              type="checkbox"
              checked={selectedTags.includes(tag.tagId)}
              onChange={() => handleTagToggle(tag.tagId)}
            />
            <span
              className={`tag-badge ${selectedTags.includes(tag.tagId) ? 'selected' : 'unselected'}`}
              style={{
                color: selectedTags.includes(tag.tagId) ? 'white' : tag.color,
                backgroundColor: selectedTags.includes(tag.tagId) ? tag.color : 'white',
                borderColor: selectedTags.includes(tag.tagId) ? 'transparent' : tag.color,
              }}
            >
              {tag.name}
            </span>
          </label>
        ))}
        {/* タグリセット */}
        {/* <button
          className="btn btn-border-secondary"
          onClick={() => setSelectedTags([])}
        >
          タグリセット
        </button> */}
      </div>
    </div>
  );
};

export default TagSelector;
