import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { resetPassword } from '../../api';

const ResetPassword: React.FC = () => {
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [showPasswords, setShowPasswords] = useState({
    new: false,
    confirm: false
  });
  const navigate = useNavigate();
  const location = useLocation();
  const resetToken = new URLSearchParams(location.search).get('token'); // Get token from URL

  // パスワードの有効性検査
  const validatePassword = (password: string) => password.length >= 8 && /^(?=.*[a-zA-Z])(?=.*\d).+$/.test(password);

  const handlePasswordToggle = (field: 'new' | 'confirm') => {
    setShowPasswords(prev => ({ ...prev, [field]: !prev[field] }));
  };

  const handleResetPassword = async () => {
    if (newPassword !== confirmPassword) {
      setError('パスワードが一致しません。');
      return;
    }

    if (!validatePassword(newPassword)) {
      setError('パスワードは8文字以上で、英数字を含めてください。');
      return;
    }

    try {
      if (resetToken) {
        const response = await resetPassword(resetToken, newPassword);
        if (response.status === 200) {
          alert('パスワードがリセットされました。');
          navigate('/login');
        }
      } else {
        setError('パスワードリセットリンクが無効です。再度リクエストしてください。');
      }
    } catch (error) {
      setError('パスワードリセットに失敗しました。');
    }
  };

  return (
    <main>
      <div className="container-small">
        <h1>パスワードリセット</h1>
        {resetToken ? (
          <>
            {error && <div className="error-message">{error}</div>}
            <div className="password-container">
              <input
                type={showPasswords.new ? 'text' : 'password'}
                placeholder="新しいパスワード"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                required
              />
              <span
                className="material-icons"
                onClick={() => handlePasswordToggle('new')}
              >
                {showPasswords.new ? 'visibility_off' : 'visibility'}
              </span>
            </div>
            <div className="password-container">
              <input
                type={showPasswords.confirm ? 'text' : 'password'}
                placeholder="新しいパスワード（確認）"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                required
              />
              <span
                className="material-icons"
                onClick={() => handlePasswordToggle('confirm')}
              >
                {showPasswords.confirm ? 'visibility_off' : 'visibility'}
              </span>
            </div>
            <button className="btn btn-primary" onClick={handleResetPassword}>パスワードをリセット</button>
          </>
        ) : (
          <p>パスワードリセットリンクが無効です。<br />新しいパスワードリセットをリクエストしてください。</p>
        )}
      </div>
    </main>
  );
};

export default ResetPassword;
