export const ALLOWED_FILE_TYPES = [
    // 画像ファイル
    'image/jpeg',
    'image/png',
    'image/gif',
    'image/bmp',
    'image/svg+xml',
    'image/webp',
  
    // ドキュメントファイル
    'application/pdf',                      // PDF
    'application/msword',                   // Word (doc)
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',  // Word (docx)
    'application/vnd.ms-excel',             // Excel (xls)
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',  // Excel (xlsx)
    'application/vnd.ms-powerpoint',        // PowerPoint (ppt)
    'application/vnd.openxmlformats-officedocument.presentationml.presentation', // PowerPoint (pptx)
  
    // テキストファイル
    'text/plain',                           // テキストファイル (txt)
    'application/rtf',                      // リッチテキストフォーマット (rtf)
    'text/csv',                             // CSVファイル (csv)
  
    // 圧縮ファイル
    'application/zip',                      // ZIP圧縮ファイル
    'application/x-7z-compressed',          // 7z圧縮ファイル
    'application/x-rar-compressed',         // RAR圧縮ファイル
  
    // ビデオファイル
    'video/mp4',
    'video/mpeg',
    'video/webm',
    'video/ogg',
  
    // オーディオファイル
    'audio/mpeg',
    'audio/ogg',
    'audio/wav',
  
    // その他の安全なファイル
    'application/json',                     // JSONファイル
    'application/xml',                      // XMLファイル
    'text/html',                            // HTMLファイル
  ];