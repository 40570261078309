import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { login } from '../../api/auth';
import { setCookie } from '../../utils/cookieUtils';
import { useAuth } from '../../contexts/AuthContext';
// import './Login.css';

const Login: React.FC = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const [showPasswords, setShowPasswords] = useState(false);
  const navigate = useNavigate();
  const { loginAuth } = useAuth();

  // サインイン処理を実行
  const handleLogin = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    try {
      await login(email, password).then((response) => {
        const { accessToken, refreshToken } = response.data;

        // ローカルストレージにデータを保存
        localStorage.setItem('fixhub_auth_ktn', accessToken);

        // クッキーにリフレッシュトークンを保存
        setCookie('fixhub_rfr_ktn', refreshToken, 7);

        setError(null);
        loginAuth(accessToken);
        navigate('/');
      }).catch((error) => {
        if (error.response && error.response.data) {
          const { identifier } = error.response.data;
          if (identifier === "INVALID_CREDENTIALS") {
            setError('ログインに失敗しました。メールまたはパスワードが正しくありません。');
          } else if (identifier === "WITHDRAWN_USER") {
            setError('ログインに失敗しました。このアカウントは退会済みです。');
          }
        } else {
          setError('ログインに失敗しました。メールまたはパスワードが正しくありません。');
        }
      });
    } catch (error) {
      setError('ログインに失敗しました。メールまたはパスワードが正しくありません。');
    } finally {
      setLoading(false);
    }
  };

  return (
    <main>
      <div className="container-small">
        <form onSubmit={handleLogin}>
          <h1>Fixhub</h1>
          <h2>ログイン</h2>
          <div>
            {error && <div className="error-message">{error}</div>}
            <input
              type="email"
              placeholder="メールアドレス"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              disabled={loading}
            />
          </div>
          <div className="password-container">
            <input
              type={showPasswords ? 'text' : 'password'}
              placeholder="パスワード"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              disabled={loading}
            />
            <span
              className="material-icons"
              onClick={() => setShowPasswords(!showPasswords)}
            >
              {showPasswords ? 'visibility_off' : 'visibility'}
            </span>
          </div>
          <button type="submit" className="btn btn-primary" disabled={loading}>
            {loading ? <span className="text-primary">ログイン中…</span> : 'ログイン'}
          </button>
        </form>
        <div>
          <p><a className="text-blue" href="/forget-password">パスワードを忘れた方</a></p>
        </div>
      </div>
    </main>
  );
};

export default Login;
