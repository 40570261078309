import React, { useEffect, useState } from 'react';
import { fetchErrorReportsCount } from '../../../api';
import { ErrorReportsType } from '../../../types/ErrorReportsTypes';

const ErrorStatusList: React.FC = () => {
  const [errorStatus, setErrorStatus] = useState<ErrorReportsType | null>(null);

  useEffect(() => {
    fetchErrorReportsCount()
      .then(response => setErrorStatus(response.data))
      .catch(error => console.error(error));
  }, []);

  return (
    <div className="dashboard-item">
      <h3>エラーステータスリスト</h3>
      <div className="status-summary">
        <div>未解決のエラー: {errorStatus?.未解決 || 0}件</div>
        <div>処理中のエラー: {errorStatus?.処理中 || 0}件</div>
        <div>解決済みのエラー: {errorStatus?.解決済み || 0}件</div>
      </div>
    </div>
  );
};

export default ErrorStatusList;
