import React, { useState, useEffect } from 'react';

interface FeedbackFormProps {
  postId: number;
  selectedText: string;
  startIndex: number;
  endIndex: number;
  onSubmit: (content: string) => void;
  onCancel: () => void;
  initialContent?: string; // 初期コンテンツ
  isEditMode?: boolean; // 編集モードフラグ
  isSubmitting: boolean; // 保存中フラグ
}

const FeedbackForm: React.FC<FeedbackFormProps> = ({ postId, selectedText, startIndex, endIndex, onSubmit, onCancel, initialContent = '', isEditMode = false, isSubmitting }) => {
  const [content, setContent] = useState(initialContent);

  useEffect(() => {
    setContent(initialContent);
  }, [initialContent]);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onSubmit(content);
  };

  return (
    <form onSubmit={handleSubmit} className="feedback-form">
      <p className="bg-primary p-2 font-small text-secondary">{selectedText}</p>
      <textarea
        value={content}
        onChange={(e) => setContent(e.target.value)}
        placeholder="フィードバックを入力してください"
        required
      />
      <div className="items-center">
        <button type="button" className="btn btn-secondary" onClick={onCancel} disabled={isSubmitting}>キャンセル</button>
        {isEditMode ? (
          <button type="submit" className="btn btn-primary mr-2" disabled={isSubmitting}>
            {isSubmitting ? '保存中…' : '保存'}
          </button>
        ) : (
          <button type="submit" className="btn btn-primary mr-2" disabled={isSubmitting}>
            {isSubmitting ? '登録中…' : '登録'}
          </button>
        )}
      </div>
    </form>
  );
};

export default FeedbackForm;