import React, { useState, useEffect } from 'react';
import { Feedback } from '../../../types/FeedbackTypes';
import FeedbackForm from './FeedbackForm';
import { fetchFeedbacks, deleteFeedback, updateFeedback } from '../../../api/feedback';

interface FeedbackListProps {
  feedbacks: Feedback[];
  // ハイライト機能　(以降のバージョンで実装予定)
  // onFeedbackHover: (feedback: Feedback, startOffset: number, endOffset: number) => void;
  // onFeedbackLeave: () => void;
  postId: number; // postIdを受け取ってフィードバックリストを更新するために使用
}

// ハイライト機能　(以降のバージョンで実装予定)
// const FeedbackList: React.FC<FeedbackListProps> = ({ feedbacks, onFeedbackHover, onFeedbackLeave, postId }) => {
const FeedbackList: React.FC<FeedbackListProps> = ({ feedbacks, postId }) => {
  const [editingFeedback, setEditingFeedback] = useState<Feedback | null>(null);
  const [feedbackList, setFeedbackList] = useState<Feedback[]>(feedbacks);
  const [isSubmitting, setIsSubmitting] = useState(false); // 保存中フラグ

  useEffect(() => {
    setFeedbackList(feedbacks);
  }, [feedbacks]);

  // フィードバック削除処理
  const handleDelete = async (id: number) => {
    if (window.confirm('フィードバックを本当に削除しますか？')) {
      try {
        await deleteFeedback(id);
        alert('削除されました。');
        // フィードバックリストを更新するための処理を追加
        const updatedFeedbacks = await fetchFeedbacks(postId);
        setFeedbackList(updatedFeedbacks.data);
      } catch (error) {
        console.error('削除に失敗しました:', error);
        alert('削除に失敗しました。');
      }
    }
  };

  // フィードバック修正処理
  const handleEdit = (feedback: Feedback) => {
    setEditingFeedback(feedback);
  };

  // フィードバック保存処理
  const handleSave = async (content: string) => {
    if (editingFeedback) {
      try {
        setIsSubmitting(true);
        const result = await updateFeedback(editingFeedback.feedbackId, { ...editingFeedback, feedbackContent: content });
        if(!result) {
          throw new Error('保存に失敗しました。');
        }
        setEditingFeedback(null);
        // フィードバックリストを更新するための処理を追加
        const updatedFeedbacks = await fetchFeedbacks(postId);
        setFeedbackList(updatedFeedbacks.data);
      } catch (error) {
        console.error('保存に失敗しました:', error);
        alert('保存に失敗しました。');
      } finally {
        setIsSubmitting(false);
      }
    }
  };

  return (
    <div className="feedback-list">
      <h4>フィードバック</h4>
      {feedbackList.map((feedback) => (
        <div
          key={feedback.feedbackId}
          className="feedback-item"

        // ハイライト機能　(以降のバージョンで実装予定)
        // onMouseEnter={() => onFeedbackHover(feedback, feedback.startOffset, feedback.endOffset)}
        // onMouseLeave={onFeedbackLeave}
        >
          {editingFeedback && editingFeedback.feedbackId === feedback.feedbackId ? (
            <FeedbackForm
              postId={editingFeedback.postId}
              selectedText={editingFeedback.draggedText}
              startIndex={editingFeedback.startOffset}
              endIndex={editingFeedback.endOffset}
              onSubmit={handleSave}
              onCancel={() => setEditingFeedback(null)}
              initialContent={editingFeedback.feedbackContent} // 初期コンテンツを渡す
              isEditMode={true} // 編集モードを示すフラグ
              isSubmitting={isSubmitting} // 保存中フラグ
            />
          ) : (
            <>
              <span className="font-small text-secondary bold">{feedback.author === undefined || feedback.author.isWithdrawn ? "退会済みユ��ザー" : feedback.author.handleName}</span>
              {feedback.draggedText ?
                <p className="bg-primary p-2">{feedback.draggedText?.length > 30 ? feedback.draggedText.slice(0, 30) + '...' : feedback.draggedText}</p>
                : null}
              <p>{feedback.feedbackContent}</p>
              <p>作成日: {new Date(feedback.createdAt).toLocaleString()}</p>
              {feedback.isAuthor ?
                <>
                  <button className="btn btn-secondary mr-2" onClick={() => handleEdit(feedback)}>修正</button>
                  <button className="btn btn-delete" onClick={() => handleDelete(feedback.feedbackId)}>削除</button>
                </>
                : null}
            </>
          )}
        </div>
      ))}
    </div>
  );
};

export default FeedbackList;