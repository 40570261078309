import React, { useEffect, useState } from 'react';
import { fetchDraftPosts, deleteDraftPost } from '../../api';
import { useNavigate } from 'react-router-dom';

interface DraftPost {
    tempPostId: number;
    postId?: number;
    title: string;
    updatedAt: string;
}

const DraftListPage: React.FC = () => {
    const [draftPosts, setDraftPosts] = useState<DraftPost[]>([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const navigate = useNavigate();

    const loadDraftPosts = async (page: number) => {
        try {
            const response = await fetchDraftPosts(page);
            const { data } = response.data;
            setDraftPosts(data);
            setCurrentPage(response.data.pagination.currentPage);
            setTotalPages(response.data.pagination.totalPages);
        } catch (error) {
            console.error('Failed to fetch draft posts:', error);
        }
    };

    const handleEdit = (tempPostId: number, postId?: number) => {
        const path = postId 
            ? `/posts/edit?type=draft&id=${tempPostId}`
            : `/posts/create?draftId=${tempPostId}`;
        navigate(path);
    };

    const handleDelete = async (tempPostId: number) => {
        if (window.confirm('この投稿を削除しますか?')) {
            try {
                await deleteDraftPost(tempPostId);
                alert('削除に成功しました。');
                loadDraftPosts(currentPage);
            } catch (error) {
                console.error('Failed to delete draft post:', error);
            }
        }
    };

    const handlePageChange = (page: number) => {
        setCurrentPage(page);
        loadDraftPosts(page);
    };

    useEffect(() => {
        loadDraftPosts(currentPage);
    }, [currentPage]);

    return (
        <div className="container">
            <section className="section">
                <h2>一時保存された投稿</h2>
                <ul className="list">
                    {draftPosts.map((post) => (
                        <li key={post.tempPostId}>
                            <div className="post-info">
                                <strong>{post.title}</strong>
                                <div className="date">{new Date(post.updatedAt).toLocaleDateString()} 保存</div>
                            </div>
                            <div className="actions">
                                <button className="btn btn-secondary mr-2" onClick={() => handleEdit(post.tempPostId, post.postId)}>編集</button>
                                <button className="btn btn-delete" onClick={() => handleDelete(post.tempPostId)}>削除</button>
                            </div>
                        </li>
                    ))}
                </ul>
                <div className="pagination">
                    {[...Array(totalPages)].map((_, index) => (
                        <button 
                            key={index} 
                            className={currentPage === index + 1 ? 'active' : ''} 
                            onClick={() => handlePageChange(index + 1)}>
                            {index + 1}
                        </button>
                    ))}
                </div>
            </section>
        </div>
    );
};

export default DraftListPage;
