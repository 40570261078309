import React, { useEffect, useState } from 'react';
import { fetchUserPostCount } from '../../../api';

const PostCount: React.FC = () => {
  const [count, setCount] = useState<number>(0);

  useEffect(() => {
    fetchUserPostCount().then((response) => setCount(response.data.count));
  }, []);

  return <h3>貢献した投稿数: {count}件</h3>;
};

export default PostCount;
