import { PostRequest } from '../types/PostTypes';
import { apiClient } from './apiClient';
import { PostSearchRequest } from '../types/PostTypes';

export const fetchPosts = () => apiClient.get('/posts');
export const fetchPost = (id: number) => apiClient.get(`/posts/${id}`);
export const fetchPostForEdit = (id: number) => apiClient.get(`/posts/${id}?isEdit=true`);  // 編集用に投稿を取得
export const createPost = (post: PostRequest) => apiClient.post('/posts', post);
export const updatePost = (id: number, post: PostRequest) => apiClient.put(`/posts/${id}`, post);
export const deletePost = (id: number) => apiClient.delete(`/posts/${id}`);
export const addFilesToPost = (postId: number, fileIds: number[]) => apiClient.post(`/posts/${postId}/files`, fileIds);
export const fetchRecentPosts = (page: number = 1, pageSize: number = 10) => apiClient.get(`/posts/recent?page=${page}&pageSize=${pageSize}`);  // 14日間以内の投稿を取得
export const fetchPostsByUserId = (page: number = 1) => apiClient.get(`/posts/user?page=${page}`);
export const fetchUserErrorReports = (page: number = 1) => apiClient.get(`/posts/user/error-reports?page=${page}`); // ユーザーのエラーレポートを取得
export const searchPosts = (searchRequest: PostSearchRequest, page: number = 1) => 
    apiClient.post(`/posts/search?page=${page}`, searchRequest);
export const fetchUserPostCount = () => apiClient.get('/posts/user/count'); // ユーザーの投稿数を取得
