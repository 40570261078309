import { apiClient } from './apiClient';

export const uploadFile = (formData: FormData) => {
    return apiClient.postNoJson('/file/upload', formData);
}

export const downloadFile = (fileId: number) => {
    return apiClient.get(`/file/download/${fileId}`);
}

export const deleteFile = (fileId: number) => {
    return apiClient.delete(`/file/delete/${fileId}`);
}

// 画像のSAS URLを取得
export const getSasUrl = (fileName: string) => {
    return apiClient.get(`/file/get-sas-url/${fileName}`);
}