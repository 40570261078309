import React from 'react';
import { useNavigate } from 'react-router-dom';

const Settings: React.FC = () => {
  const navigate = useNavigate();

  const handlePasswordChange = () => {
    navigate('/change-password');
  };

  const handleAccountDelete = () => {
    navigate('/withdraw-confirmation');
  };

  return (
    <main>
      <div className="container center-items">
        <h1>設定</h1>
        <div className="mb-2">
          <p>パスワード変更</p>
          <hr />
          <button onClick={handlePasswordChange} className="btn btn-primary">パスワード変更</button>
        </div>
        <div>
          <p>アカウント削除</p>
          <hr />
          <button onClick={handleAccountDelete} className="btn btn-danger">退会</button>
        </div>
      </div>
    </main>
  );
};

export default Settings;
