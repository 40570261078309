import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import MDEditor from '@uiw/react-md-editor';
import { fetchPost, deletePost, getSasUrl } from '../../api';
import { fetchFeedbacks, createFeedback } from '../../api/feedback';
import { Feedback, FeedbackRequest } from '../../types/FeedbackTypes';
import { Post } from '../../types/PostTypes';
import { ProfileImage } from '../../types/FileTypes';
import FeedbackList from './components/FeedbackList';
import FeedbackForm from './components/FeedbackForm';
import profile from '../../assets/images/default-profile-black.png';
import html2pdf from 'html2pdf.js';
import './PostDetail.css';

const PostDetail: React.FC = () => {
  const { postId } = useParams<{ postId: string }>();
  const navigate = useNavigate();
  const [post, setPost] = useState<Post | null>(null);
  const [feedbacks, setFeedbacks] = useState<Feedback[]>([]);
  const [selectedText, setSelectedText] = useState('');
  const [showFeedbackForm, setShowFeedbackForm] = useState(false);
  const [feedbackPosition, setFeedbackPosition] = useState({ top: 0, left: 0 });
  const [selectionIndices, setSelectionIndices] = useState({ startIndex: 0, endIndex: 0 });
  const [authorName, setAuthorName] = useState('');
  const [isAuthor, setIsAuthor] = useState(false);
  const [profileImage, setProfileImage] = useState<ProfileImage>();
  const [authorProfile, setAuthorProfile] = useState<string>(profile);
  const contentRef = useRef<HTMLDivElement>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);  // 登録中フラグ

  //フィードバックのハイライト表示機能は以降のバージョンで実装予定
  // const [postContent, setPostContent] = useState('');
  // const [highlightedContent, setHighlightedContent] = useState('');

  useEffect(() => {
    if (postId) {
      fetchPost(Number(postId)).then(result => {
        setPost(result.data);
        // ハイライト機能　(以降のバージョンで実装予定)
        // setHighlightedContent(result.data.content);
        // setPostContent(result.data.content);
        setIsAuthor(result.data.isAuthor);
        if (result.data.author && !result.data.author.isWithdrawn) {
          const { handleName, profileImage } = result.data.author;
          setAuthorName(handleName);
          setProfileImage(profileImage);
        } else {
          setAuthorName('退会済みユーザー');
        }
      }).catch(error => {
        if (error.response && error.response.data) {
          const { identifier } = error.response.data;
          if (identifier === 'POST_DELETED') {
            alert('投稿が見つかりません。');
            navigate('/posts_list');
          } else {
            alert('エラーが発生しました。');
          }
        }
      });

      fetchFeedbacks(Number(postId)).then(result => {
        setFeedbacks(result.data);
      }).catch(error => {
        console.error('フィードバック取得失敗:', error);
      });
    }
  }, [postId, navigate]);

  useEffect(() => {
    if (profileImage) {
      getSasUrl(profileImage.blobFileName).then(response => {
        setAuthorProfile(response.url);
      }).catch(error => {
        console.error('プロフィール画像取得失敗:', error);
      });
    }
  }, [profileImage]);

  useEffect(() => {
    // フィードバックフォーム外をクリックした場合、フォームを閉じる
    const handleClickOutside = (event: MouseEvent) => {
      if (showFeedbackForm) {
        const feedbackFormContainer = document.querySelector('.feedback-form-container');
        if (feedbackFormContainer && !feedbackFormContainer.contains(event.target as Node)) {
          setShowFeedbackForm(false);
        }
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showFeedbackForm]);

  async function handleFeedbackSubmit(content: string) {
    if (postId) {
      const feedbackRequest: FeedbackRequest = {
        postId: Number(postId),
        draggedText: selectedText,
        feedbackContent: content,
        startOffset: selectionIndices.startIndex,
        endOffset: selectionIndices.endIndex
      };

      try {
        setIsSubmitting(true);
        const result = await createFeedback(feedbackRequest);
        if (!result) {
          throw new Error('フィードバック作成失敗');
        }
        setShowFeedbackForm(false);
        const createdFeedbacks = await fetchFeedbacks(Number(postId));
        setFeedbacks(createdFeedbacks.data);
      } catch (error) {
        console.error('フィードバック作成失敗:', error);
      } finally {
        setIsSubmitting(false);
      }
    }
  }

  const handleTextSelection = () => {
    const selection = window.getSelection();
    if (selection && selection.toString().trim() !== '') {
      const selectedText = selection.toString();
      setSelectedText(selectedText);

      const range = selection.getRangeAt(0);
      const preSelectionRange = range.cloneRange();
      if (contentRef.current) {
        preSelectionRange.selectNodeContents(contentRef.current);
        preSelectionRange.setEnd(range.startContainer, range.startOffset);
        const start = preSelectionRange.toString().length;

        setSelectionIndices({
          startIndex: start,
          endIndex: start + selectedText.length
        });

        const rect = range.getBoundingClientRect();
        setFeedbackPosition({
          top: rect.bottom + window.scrollY,
          left: rect.left + window.scrollX,
        });
        setShowFeedbackForm(true);
      } else {
        setShowFeedbackForm(false);
      }
    } else {
      setShowFeedbackForm(false);
    }
  };


  // ハイライト機能　(以降のバージョンで実装予定)
  // const handleTextSelection = () => {
  //   const selection = window.getSelection();
  //   if (selection && selection.toString().trim() !== '') {
  //     const selectedText = selection.toString();

  //     const range = selection.getRangeAt(0);
  //     const preSelectionRange = range.cloneRange();
  //     if (contentRef.current) {
  //       preSelectionRange.selectNodeContents(contentRef.current);
  //       preSelectionRange.setEnd(range.startContainer, range.startOffset);

  //       // Calculate start and end indices relative to the content
  //       const start = preSelectionRange.toString().length;
  //       setSelectionIndices({
  //         startIndex: start,
  //         endIndex: start + selectedText.length,
  //       });

  //       // Show feedback form near the selected text
  //       const rect = range.getBoundingClientRect();
  //       setFeedbackPosition({
  //         top: rect.bottom + window.scrollY,
  //         left: rect.left + window.scrollX,
  //       });
  //       setShowFeedbackForm(true);
  //     } else {
  //       setShowFeedbackForm(false);
  //     }
  //   } else {
  //     setShowFeedbackForm(false);
  //   }
  // };

  // ハイライト機能　(以降のバージョンで実装予定)
  // const highlightFeedback = (feedback: Feedback) => {
  //   const { startOffset, endOffset, draggedText } = feedback;

  //   // Wrap highlighted content in <span> tag
  //   const highlighted = `${postContent.slice(0, startOffset)}<span class="highlighted">${postContent.slice(startOffset, endOffset)}</span>${postContent.slice(endOffset)}`;

  //   // Update state with highlighted content
  //   setHighlightedContent(highlighted);
  // };

  // const removeHighlight = () => {
  //   // Remove the <span class="highlighted">...</span> tags
  //   const cleanedContent = highlightedContent.replace(/<span class="highlighted">(.*?)<\/span>/g, '$1');

  //   // Update the state to remove highlight
  //   setHighlightedContent(cleanedContent);
  // };

  const handleCopyLink = () => {
    const currentUrl = window.location.href;
    navigator.clipboard.writeText(currentUrl).then(() => {
      alert('リンクがクリップボードにコピーされました。');
    });
  };

  const handlePdfDownload = () => {
    const content = document.querySelector('.post-content');
    if (content) {
      const pdfContent = content.cloneNode(true) as HTMLElement;

      // アクションボタンを削除
      const actionButtons = pdfContent.querySelectorAll('.action-buttons');
      actionButtons.forEach(buttons => buttons.remove());

      // 画像をリンクに置き換え
      const images = pdfContent.querySelectorAll('img');
      images.forEach((img) => {
        const imageSrc = img.src;
        const link = document.createElement('a');
        link.href = imageSrc;
        link.textContent = img.alt;
        link.target = '_blank';
        img.replaceWith(link);
      });

      // 日付を先頭に移動
      const dateElement = pdfContent.querySelector('.date');
      if (dateElement) {
        const clonedDateElement = dateElement.cloneNode(true) as HTMLElement;
        clonedDateElement.style.color = 'gray';
        clonedDateElement.style.fontSize = 'small';
        clonedDateElement.style.textAlign = 'right';
        pdfContent.insertBefore(clonedDateElement, pdfContent.firstChild);
        dateElement.remove();
      }
      const opt = {
        margin: [5, 10, 10, 10],
        filename: `${post?.title || 'post'}.pdf`,
        image: { type: 'jpeg', quality: 0.98 },
        html2canvas: { scale: 2 },
        jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' }
      };

      html2pdf().set(opt).from(pdfContent).save();
    }
  };

  const handleEdit = () => {
    navigate(`/posts/edit?type=post&id=${postId}`);
  };

  const handleDelete = async () => {
    if (window.confirm('本当に削除しますか？')) {
      try {
        await deletePost(Number(postId));
        alert('削除されました。');
        navigate('/posts_list');
      } catch (error) {
        console.error('削除に失敗しました:', error);
        alert('削除に失敗しました。');
      }
    }
  };

  if (!post) return <div>読み込み中...</div>;

  return (
    <div className="post-detail-container">
      <div className="container">
        <div id="post-content" className="post-content">
          <div className="items-center">
            <h1>{post.title}</h1>
            <div className="date">
              <p>作成日: {new Date(post.createdAt).toLocaleString()}</p>
              <p>更新日: {new Date(post.updatedAt).toLocaleString()}</p>
            </div>
          </div>
          <div className="tags">
            {post.tags && post.tags.map((t) => (
              <span key={t.tagId} className="tag" style={{ backgroundColor: t.color }}>
                {t.name}
              </span>
            ))}
          </div>
          <div className="items-center">
            <div className="user-image-header items-center mt-2">
              <img className="mr-2" src={authorProfile || '../../assets/images/default-profile.png'} alt="Profile" />
              <h4 className="text-secondary">{authorName}</h4>
            </div>
            <div className="action-buttons">
              <button className="btn btn-secondary mr-2" onClick={handleCopyLink}>リンクコピー</button>
              <button className="btn btn-secondary" onClick={handlePdfDownload}>PDFダウンロード</button>
            </div>
          </div>

          {post.isErrorReport && post.errorReport && (
            <div className="error-report-info">
              <p>エラー状態: {post.errorReport.errorStatus}</p>
              <p>エラーメッセージ: {post.errorReport.errorCode}</p>
              <p>エラー環境: {post.errorReport.environment}</p>
            </div>
          )}
          {/* ハイライト機能　(以降のバージョンで実装予定) */}
          <div ref={contentRef} onMouseUp={handleTextSelection}>
            <MDEditor.Markdown
              // source={highlightedContent}
              source={post.content}
              components={{
                a: ({ node, ...props }) => (
                  <a
                    {...props}
                    target="_blank"
                  >
                    {props.children}
                  </a>
                ),
              }}
            />
          </div>

          <div className="action-buttons">
            {isAuthor && (
              <div>
                <button className="btn btn-border mr-2" onClick={handleEdit}>修正</button>
                <button className="btn btn-danger" onClick={handleDelete}>削除</button>
              </div>
            )}
          </div>
        </div>
      </div>

      {/* フィードバック */}
      {feedbacks.length > 0 ? (
        <div className="feedback-container">
          <FeedbackList
            feedbacks={feedbacks}
            // onFeedbackHover={highlightFeedback}
            // onFeedbackLeave={removeHighlight}
            postId={Number(postId)} // postIdを渡す
          />
        </div>
      ) : null}
      {showFeedbackForm && (
        <div className="feedback-form-container" style={{ top: feedbackPosition.top, left: feedbackPosition.left }}>
          <FeedbackForm
            postId={Number(postId)}
            selectedText={selectedText}
            startIndex={selectionIndices.startIndex}
            endIndex={selectionIndices.endIndex}
            onSubmit={handleFeedbackSubmit}
            onCancel={() => setShowFeedbackForm(false)}
            isSubmitting={isSubmitting} // 登録中フラグを渡す
          />
        </div>
      )}
    </div>
  );
};

export default PostDetail;