import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { fetchTags, fetchPostForEdit, updatePost, fetchDraftPostForEdit, createDraftPostAndPostId, updateDraftPost, addFilesToPost, addFilesToDraftPost } from '../../api';
import { ERROR_STATUS, ErrorStatus } from '../../constants/ErrorStatus';
import { Tag } from '../../types/TagTypes';
import { PostRequest } from '../../types/PostTypes';
import PostForm from './components/PostForm';
import ErrorFields from './components/ErrorFields';
import TagSelector from './components/TagSelector';
import { DraftPostsRequest } from '../../types/DraftPostsTypes';

const EditPosts: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [postId, setPostId] = useState<number | undefined>(undefined);
  const [isErrorReport, setIsErrorReport] = useState<boolean>(false);
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [tags, setTags] = useState<Tag[]>([]);
  const [selectedTags, setSelectedTags] = useState<number[]>([]);
  const [tempPostId, setTempPostId] = useState<number | undefined>(undefined);
  const [type, setType] = useState<string | null>(null);
  const [errorFields, setErrorFields] = useState({
    environment: '',
    message: '',
    status: ERROR_STATUS.UNSOLVED as ErrorStatus
  });
  const [fileIds, setFileIds] = useState<number[]>([]);
  const [isUpdatingPost, setIsUpdatingPost] = useState(false);
  const [isUpdatingDraftPost, setIsUpdatingDraftPost] = useState(false);

  useEffect(() => {
    fetchTags().then(result => setTags(result.data)).catch(error => console.error('タグ取得エラー:', error));

    const searchParams = new URLSearchParams(location.search);
    const type = searchParams.get('type');
    setType(type);
    const idParam = searchParams.get('id');

    if (idParam) {
      // 一���保存投稿の場合、fetchDraftPostを実行・投稿の場合、fetchPostを実行
      const fetchFunction = type === 'draft' ? fetchDraftPostForEdit : (type === 'post' ? fetchPostForEdit : null);

      if (fetchFunction) {
        fetchFunction(Number(idParam))
          .then(result => {
            const data = result.data;
            // 一時保存投稿が投稿として登録された履歴がない場合、
            if (type === 'draft' && !data.postId) {
              navigate(`/posts/create?draftId=${idParam}`);
              return;
            }
            setPostId(data.postId);
            setTempPostId(data.tempPostId);
            setTitle(data.title);
            setContent(data.content || '');
            setSelectedTags(data.tagIds || []);
            setIsErrorReport(data.isErrorReport);
            if (data.isErrorReport) {
              const errorReport = data.errorReport;
              setErrorFields({
                environment: errorReport.environment || '',
                message: errorReport.errorCode || '',
                status: errorReport.errorStatus || ERROR_STATUS.UNSOLVED,
              });
            }
          })
          .catch(error => {
            console.error('投稿取得エラ���:', error);
            if (error.response && error.response.data) {
              const { identifier } = error.response.data;
      
              if (identifier === 'AUTHOR_MISMATCH') {
                alert('アクセス権限がありません。');
              }
            } else {
              alert('一時保存投稿の取得に失敗しました。');
            }
            navigate(-1);
          });
      } else {
          navigate('/notfound');
      }
    }
  }, [location, navigate]);

  const preparePostData = (): PostRequest => ({
    title: title,
    content: content,
    tagIDs: selectedTags,
    isErrorReport:  isErrorReport ? 1 : 0,
    ...(isErrorReport && {
      environment: errorFields.environment,
      errorCode: errorFields.message,
      errorStatus: errorFields.status,
    }),
  });

  const prepareDraftPostData = (): DraftPostsRequest => ({
    ...preparePostData()
  });

  const handleUpdatePost = async () => {
    if (!title.trim()) {
      alert('タイトルを入力してください。');
      return;
    }

    setIsUpdatingPost(true);
    const postData = preparePostData();
    try {
      await updatePost(postId!, postData);

      // ファイルをPostFilesに追加するAPIの呼び出し
      if (fileIds.length > 0) {
        await addFilesToPost(postId!, fileIds);
      }

      alert('投稿が正常に更新されました。');
      navigate(`/posts/${postId}`);
    } catch (error) {
      console.error('投稿更新エラー:', error);
      alert('投稿の更新中にエラーが発生しました。');
    } finally {
      setIsUpdatingPost(false);
    }
  };

  const handleUpdateDraftPost = async () => {
    if (!title.trim()) {
      alert('タイトルを入力してください。');
      return;
    }

    setIsUpdatingDraftPost(true);
    const draftPostData = prepareDraftPostData();
    try {
      if (tempPostId) {
        await updateDraftPost(tempPostId!, draftPostData);

        // ファイルをTempPostFilesに追加するAPIを呼び出す
        if (fileIds.length > 0) {
          await addFilesToDraftPost(tempPostId!, fileIds);
        }

        alert('一時保存が正常に更新されました。');
        navigate('/drafts');
      } else if (type === 'post' && tempPostId === undefined) {
        const result = await createDraftPostAndPostId(postId!, draftPostData);
        const newTempPostId = result.data.tempPostId;

        // ファイルをTempPostFilesに追加するAPIを呼び出す
        if (fileIds.length > 0) {
          await addFilesToDraftPost(newTempPostId, fileIds);
        }

        alert('一時保存が正常に作成されました。');
        navigate('/drafts');
      }
    } catch (error) {
      console.error('一時保存エラー:', error);
      alert('一時保存中にエラーが発生しました。');
    } finally {
      setIsUpdatingDraftPost(false);
    }
  };

  return (
    <div className="container">
      <h2>投稿編集</h2>
      <div className="form-container">
        <PostForm
          title={title}
          setTitle={setTitle}
          content={content}
          setContent={setContent}
          setFileIds={setFileIds}
        />
        {isErrorReport && (
          <ErrorFields
            errorFields={errorFields}
            setErrorFields={setErrorFields}
          />
        )}
        <TagSelector
          tags={tags}
          selectedTags={selectedTags}
          setSelectedTags={setSelectedTags}
        />
        <button type="button" className="btn btn-primary" onClick={handleUpdatePost} disabled={isUpdatingPost}>
          {isUpdatingPost ? <span className="text-secondary">登録中…</span> : '投稿編集'}
        </button>
        <button type="button" className="btn btn-secondary" onClick={handleUpdateDraftPost} disabled={isUpdatingDraftPost}>
          {isUpdatingDraftPost ? <span className="text-white">一時保存中…</span> : '一時保存'}
        </button>
        <button type="button" className="btn btn-border" onClick={() => navigate(-1)}>
          キャンセル
        </button>
      </div>
    </div>
  );
};

export default EditPosts;