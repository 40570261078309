import React from 'react';
import ErrorStatusList from './components/ErrorStatusList';
import ErrorTrendChart from './components/ErrorTrendChart';
import PostList from './components/PostList';
import TeamActivityFeed from './components/TeamActivityFeed';
import './Dashboard.css';

const Dashboard: React.FC = () => (
  <main>
    <section className="dashboard">
      <ErrorStatusList />
      <ErrorTrendChart />
      <PostList />
      <TeamActivityFeed />
    </section>
  </main>
);

export default Dashboard;
