import React from 'react';
import './Initial.css';

const Initial: React.FC = () => {
  return (
    <main className="initial-container">
      <div className="introduction">
        <h1>Fixhub</h1>
        <p>
          「Fixhub」は、長時間を要した作業内容をチームと共有しフィードバックすることで、
          業務処理のスピード向上と効率的を目標しています。
        </p>
      </div>
    </main>
  );
};

export default Initial;
