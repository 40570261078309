import React, { useEffect, useState } from 'react';
import { fetchUserErrorReports, updateErrorReportStatus } from '../../../api';
import { PostUserResponse } from '../../../types/PostTypes';
import { ERROR_STATUS } from '../../../constants/ErrorStatus';

const ErrorReportList: React.FC = () => {
  const [reports, setReports] = useState<PostUserResponse[]>([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  useEffect(() => {
    const fetchReports = async () => {
      try {
        const response = await fetchUserErrorReports(page);
        setReports(response.data);
        setTotalPages(response.pagination.totalPages); // Set totalPages from the response
      } catch (error) {
        console.error('Failed to fetch error reports:', error);
      }
    };

    fetchReports();
  }, [page]);

  const handleStatusChange = (errorReportId: number) => {
    if (window.confirm("エラーステータスを処理中に変更しますか？")) {
      updateErrorReportStatus(errorReportId, ERROR_STATUS.IN_PROGRESS);
    }
  };

  return (
    <>
      {reports.length === 0 ? (
        <div className="text-center">
          <p>報告したエラーリストがありません。</p>
        </div>
      ) : (
        <>
          <ul className="list">
            {reports.map((report) => (
              <li key={report.postId}>
                <div>
                  <strong>{report.title}</strong>
                  <div className="status">{report.errorReport?.errorStatus}</div>
                </div>
                {report.errorReport?.errorStatus === ERROR_STATUS.UNSOLVED && (
                  <button className="btn btn-border" onClick={() => report.errorReport && handleStatusChange(report.errorReport.errorReportId)}>
                    処理中に変更
                  </button>
                )}
                {report.errorReport?.errorStatus === ERROR_STATUS.IN_PROGRESS && (
                  <button className="btn btn-primary" onClick={() => window.location.href = `/posts/edit?type=post&id=${report.postId}`}>
                    解決方法を作成しに行く
                  </button>
                )}
              </li>
            ))}
          </ul>
          {/* Pagination Controls */}
          <div className="pagination-next">
            {page > 1 && (
              <button onClick={() => setPage(page - 1)}>&lt;</button>
            )}
            <span>{page}</span>
            {page < totalPages && (
              <button onClick={() => setPage(page + 1)}>&gt;</button>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default ErrorReportList;
