import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { AuthProvider } from './contexts/AuthContext';
import PrivateRoute from './components/PrivateRoute';
import Header from './components/header/Header';
import Login from './pages/auth/Login';
import Register from './pages/auth/Register';
import Profile from './pages/profile/Profile';
import Initial from './pages/Initial';
import TagsManagement from './pages/tags/TagsManagement';
import CreatePosts from './pages/posts/CreatePosts';
import EditPosts from './pages/posts/EditPosts';
import PostDetail from './pages/posts/PostDetail';
import PostList from './pages/posts/PostList';
import DraftListPage from './pages/posts/DraftListPage';
import Dashboard from './pages/home/Dashboard';
import MyPage from './pages/mypage/MyPage';
import ForgetPassword from './pages/auth/ForgetPassword';
import ResetPassword from './pages/auth/ResetPassword';
import ChangePassword from './pages/auth/ChangePassword';
import Settings from './pages/Settings';
import Withdraw from './pages/auth/Withdraw';
import WithdrawConfirmation from './pages/auth/WithdrawConfirmation';
import './App.css';
import './styles/auth.css';


const App: React.FC = () => {
  return (
    <AuthProvider>
      <Header />
      <Routes>
        {/* 公開ページ（ログイン不要） */}
        <Route path="/register" element={<Register />} />
        <Route path="/login" element={<Login />} />
        <Route path="/initial" element={<Initial />} />
        <Route path="/forget-password" element={<ForgetPassword />} />
        <Route path="/reset-password" element={<ResetPassword />} />

        {/* 管理者専用ページ */}
        <Route
          path="/admin-tags"
          element={
            <PrivateRoute requiredAdmin>
              <TagsManagement />
            </PrivateRoute>
          }
        />

        {/* ログインが必要なページ（一般ユーザーと管理者） */}
        <Route
          path="/"
          element={
            <PrivateRoute>
              <Dashboard />
            </PrivateRoute>
          }
        />
        <Route
          path="/profile"
          element={
            <PrivateRoute>
              <Profile />
            </PrivateRoute>
          }
        />
        <Route
          path="/change-password"
          element={
            <PrivateRoute>
              <ChangePassword />
            </PrivateRoute>
          }
        />
        <Route
          path="/posts/create"
          element={
            <PrivateRoute>
              <CreatePosts />
            </PrivateRoute>
          }
        />
        <Route
          path="/posts/edit"
          element={
            <PrivateRoute>
              <EditPosts />
            </PrivateRoute>
          }
        />
        <Route
          path="/posts/:postId"
          element={
            <PrivateRoute>
              <PostDetail />
            </PrivateRoute>
          }
        />
        <Route
          path="/posts-list"
          element={
            <PrivateRoute>
              <PostList />
            </PrivateRoute>
          }
        />
        <Route
          path="/drafts"
          element={
            <PrivateRoute>
              <DraftListPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/mypage"
          element={
            <PrivateRoute>
              <MyPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/settings"
          element={
            <PrivateRoute>
              <Settings />
            </PrivateRoute>
          }
        />
        <Route
          path="/account/delete"
          element={
            <PrivateRoute>
              <Withdraw />
            </PrivateRoute>
          }
        />
        <Route
          path="/withdraw-confirmation"
          element={
            <PrivateRoute>
              <WithdrawConfirmation />
            </PrivateRoute>
          }
        />

        {/* エラーページ */}
        <Route path="/not-authorized" element={<h1>権限がありません</h1>} />
        <Route path="/notfound" element={<h1>ページが見つかりません</h1>} />
        <Route path="*" element={<h1>ページが見つかりません</h1>} />
      </Routes>
    </AuthProvider>
  );
};

export default App;
