import React, { useState } from 'react';
import { requestPasswordReset } from '../../api';

const ForgetPassword: React.FC = () => {
  const [email, setEmail] = useState('');
  const [isRequestSent, setIsRequestSent] = useState(false);
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  // メールアドレスの有効性検査
  const validateEmail = (email: string) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);

  const handleRequestReset = async () => {
    setIsLoading(true);

    if (!validateEmail(email)) {
      setError('メールアドレスの形式が正しくありません。');
      setIsLoading(false);
      return;
    }

    try {
      const response = await requestPasswordReset(email);
      if (response.status === 200) {
        setIsRequestSent(true);
        setError('');
      }
    } catch (error) {
      setError('パスワードリセットのリクエストに失敗しました。');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <main>
      <div className="container-small">
        <h1>パスワードを忘れた方</h1>
        {!isRequestSent ? (
          <>
            {error && <div className="error-message">{error}</div>}
            <input
              type="email"
              placeholder="メールアドレス"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            <button className="btn btn-primary" onClick={handleRequestReset}>パスワードをリセット</button>
            {isLoading && <div className="loading-message">送信中…</div>}
          </>
        ) : (
          <p>
            パスワードリセットのためのリンクを{email}に送信しました。
            <br />メールを確認してください。
          </p>
        )}
      </div>
    </main>
  );
};

export default ForgetPassword;