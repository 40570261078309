import React from 'react';
import { TagColors } from '../../constants/TagColor';

interface ColorPickerProps {
    color: string;
    onChange: (color: string) => void;
}

const ColorPicker: React.FC<ColorPickerProps> = ({ color, onChange }) => (
    <div className="color-picker-palette">
        {TagColors.map((c) => (
            <button
                key={c}
                onClick={(e) => {
                    e.preventDefault();
                    onChange(c);
                }}
                className="color-picker-swatch"
                style={{ backgroundColor: c }}
            />
        ))}
    </div>
);

export default ColorPicker;