import React, { useEffect, useState } from 'react';
import { fetchRecentPosts } from '../../../api';
import { PostRecentResponse } from '../../../types/PostTypes';
import { useNavigate } from 'react-router-dom';

const PostList: React.FC = () => {
  const [posts, setPosts] = useState<PostRecentResponse[]>([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchPosts = async () => {
      try {
        const response = await fetchRecentPosts(page);
        setPosts(response.data);
        setTotalPages(response.pagination.totalPages);
      } catch (error) {
        console.error('Failed to fetch recent posts:', error);
      }
    }

    fetchPosts();
  }, [page]);

  return (
    <div className="dashboard-item">
      <h3 onClick={() => navigate('/posts-list')}>最近の投稿リスト</h3>
      {posts.length === 0 ? (
        <p>登録された投稿がありません。</p>
      ) : (
        <ul className="post_list">
          {posts.map(post => (
            <li key={post.postId} onClick={() => navigate(`/posts/${post.postId}`)}>
              {post.title} <span>{new Date(post.createdAt).toLocaleDateString()}</span>
            </li>
          ))}
        </ul>
      )}
      {/* Pagination Controls */}
      <div className="pagination-next">
        {page > 1 && (
          <button onClick={() => setPage(page - 1)}>&lt;</button>
        )}
        <span>{page}</span>
        {page < totalPages && (
          <button onClick={() => setPage(page + 1)}>&gt;</button>
        )}
      </div>
    </div>
  );
};

export default PostList;
